import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import biomarkers from "./biomarkers/biomarkerSlice";
import utils from "./utils/utilsSlice";

export default configureStore({
  reducer: {
    auth,
    biomarkers,
    utils,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
