import { Edit } from "@mui/icons-material";
import {
  Input,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "20px",
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

export const CustomInput = styled(Input)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

export const CustomInputLabel = ({ label, editAction, testId }) => {
  const { palette } = useTheme();

  return (
    <InputLabel
      variant="standard"
      style={{
        marginTop: "-30px",
        width: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="c14px400"
          style={{
            color: palette.neutral[700],
          }}
        >
          {label}
        </Typography>
        {editAction && (
          <Edit
            data-cy={`${testId}`}
            onClick={editAction}
            color="primary"
            style={{
              height: "16px",
              width: "16px",
              cursor: "pointer",
            }}
          />
        )}
      </Stack>
    </InputLabel>
  );
};
