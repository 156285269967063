import {
  Box,
  Button,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Dialog,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlined from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import Question from "./Question";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import { getAuthorization } from "../../../utils/helpers";
import { DOMAIN } from "../../../utils/config";
import Loading from "../../../components/Blockers/Loading";
import { setNotify } from "../../../redux/utils/utilsSlice";
import { useDispatch } from "react-redux";
import EditSchedule from "../SurveyVersion/EditSchedule";
import Close from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";

export const question_types = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "mcq",
    label: "Multiple choice (Checkbox)",
  },
  {
    value: "scq",
    label: "Single choice (Radio-button)",
  },
];

export default function Questions() {
  const { palette } = useTheme();
  const { id, surveyVersionId, surveyId } = useParams();
  const [loadingSurvey, setLoadingSurvey] = useState(true);
  const [survey, setSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("Enter Question");
  const [loading, setLoading] = useState(false);
  const [swapping, setSwapping] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [addOption, setAddOption] = useState(false);
  const [open, setOpen] = useState(false);

  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const navigate = useNavigate();

  useEffect(() => {
    setLoadingSurvey(true);
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/`,
      params: {
        trial_id: id,
        id: surveyId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      setSurvey(res.data.payload[0]);
      setLoadingSurvey(false);
    });
  }, [id, surveyId]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/versions/questions/`,
      params: {
        version_id: surveyVersionId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      setQuestions(res.data.payload);
    });
  }, [surveyVersionId]);

  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setAddQuestion(true);

    try {
      // make API request
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/surveys/versions/questions/`,
        data: {
          title,
          question_type: question_types[0].value,
          survey_version: surveyVersionId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      setQuestions([
        ...questions,
        {
          id: res.data.id,
          order_id: res.data.order_id,
          title: res.data.title,
          question_type: res.data.question_type,
        },
      ]);

      setAddQuestion(false);

      // notify
    } catch (err) {
      // set loading to false
      setLoading(false);
      setError("Error while adding question");
    }
  };

  const editQuestion = (updatedValue, question, eventType) => {
    let data = {};
    switch (eventType) {
      case "changeTitle":
        data = {
          ...data,
          title: updatedValue,
        };
        break;
      case "changeQuestionType":
        data = {
          ...data,
          question_type: updatedValue,
        };
        break;
      default:
        break;
    }

    axios({
      method: "PATCH",
      url: `${DOMAIN}/surveys/versions/questions/`,
      params: {
        id: question.id,
      },
      data: {
        ...data,
        survey_version: surveyVersionId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      const newQuestions = questions.map((q) => {
        if (q.id === question.id) {
          return {
            ...res.data,
          };
        }
        return q;
      });
      setQuestions(newQuestions);
    });
  };

  const reorderQuestions = (question, direction) => {
    const question1 = questions.filter((i) => i === question)[0];
    const question1Index = questions.indexOf(question1);
    const question2 = questions[question1Index + direction];

    setSwapping(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/surveys/versions/questions/swap-order/`,
      data: {
        q1: question1.id,
        q2: question2.id,
        survey_version: surveyVersionId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then(() => window.location.reload());
  };

  const handleDelete = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      await axios({
        method: "DELETE",
        url: `${DOMAIN}/surveys/versions/`,
        data: {
          id: surveyVersionId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Deleted",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
      navigate(`/trial/${id}/survey`);
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  return (
    <Loading loading={loading || loadingSurvey}>
      <Grid
        container
        style={{
          height: "100%",
          // minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
              }}
            >
              {/* START: First Row */}
              <Stack
                px="53px"
                py="12px"
                paddingTop="24px"
                paddingBottom="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => navigate(-1)}
                  >
                    <IconButton>
                      <ArrowBackIcon sx={{ color: palette.neutral.dark }} />
                    </IconButton>
                    <Typography
                      variant="c20px600"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[900],
                      }}
                    >
                      {survey.title}
                    </Typography>
                  </Stack>
                  {/* START: Edit Schedule Button */}
                  <Button
                    data-cy="button__editSchedule"
                    sx={{
                      color: palette.primary[500],
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={handleClickOpen}
                    startIcon={
                      <EditOutlined sx={{ color: palette.primary[500] }} />
                    }
                    variant="text"
                  >
                    Edit Schedule
                  </Button>
                  <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Typography
                        variant="c20px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        Edit Schedule
                      </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent>
                      <EditSchedule
                        surveyVersion={
                          survey?.versions?.filter(
                            (sv) => sv.id === parseInt(surveyVersionId)
                          )[0]
                        }
                      />
                    </DialogContent>
                  </BootstrapDialog>
                  {/* END: Edit Schedule Button */}
                </Stack>
              </Stack>
              {/* END: First Row */}
            </Stack>
            {/* END: Top Nav */}
          </Box>
          {/* START:Body*/}
          <Stack
            minHeight="90vh"
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            sx={{ paddingTop: "32px" }}
          >
            {/* START: Body */}
            {questions.length
              ? questions
                  .sort((a, b) => a.order_id - b.order_id)
                  .map((question, index) => (
                    <Question
                      key={question.order_id}
                      index={index}
                      question={question}
                      editQuestion={editQuestion}
                      reorderQuestions={reorderQuestions}
                      questions={questions}
                      title={title}
                      setTitle={setTitle}
                      question_types={question_types}
                      setQuestions={setQuestions}
                      surveyVersionId={surveyVersionId}
                      swapping={swapping}
                      addOption={addOption}
                      setAddOption={setAddOption}
                      addQuestion={addQuestion}
                      setAddQuestion={setAddQuestion}
                    />
                  ))
              : null}
            {/* END: Body */}
            <form onSubmit={handleAddQuestion}>
              <Stack
                sx={{
                  paddingBottom: "62px",
                  paddingLeft: "40px",
                  width: "688px",
                }}
              >
                {error && (
                  <p
                    style={{
                      color: palette.error.main,
                      fontSize: "14px",
                      marginBottom: "10px",
                    }}
                  >
                    {error}
                  </p>
                )}
                <Button
                  type="submit"
                  disabled={addQuestion}
                  disableElevation
                  sx={{
                    backgroundColor: palette.neutral[100],
                    color: palette.primary[500],
                    padding: "16px 24px 16px 24px ",
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Question
                </Button>
              </Stack>
            </form>
          </Stack>
          <Paper
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              height: "91px",
              overflow: "hidden",
              padding: "24px 48px 24px 48px",
            }}
            elevation={0}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              // sx={{ padding: "36px 48px 0px 48px" }  }
            >
              <Button
                disabled
                variant="text"
                // endIcon={<FileDownloadOutlinedIcon />}
              >
                {/* Import Survey */}
              </Button>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  sx={{
                    padding: "12px 16px 12px 16px",
                    width: "120px",
                    height: "43px",
                    borderRadius: "6px",
                  }}
                  variant="outlined"
                  disableElevation
                  color={
                    searchParams.get("utm_source") === "active" ||
                    searchParams.get("utm_source") === "inactive"
                      ? "error"
                      : "primary"
                  }
                  onClick={
                    searchParams.get("utm_source") === "active" ||
                    searchParams.get("utm_source") === "inactive"
                      ? handleDelete
                      : () => navigate(`/trial/${id}/survey`)
                  }
                  startIcon={
                    searchParams.get("utm_source") === "active" ||
                    searchParams.get("utm_source") === "inactive" ? null : (
                      <ArrowBackIcon />
                    )
                  }
                >
                  {searchParams.get("utm_source") === "active" ||
                  searchParams.get("utm_source") === "inactive"
                    ? "Delete"
                    : "Back"}
                </Button>
                <Button
                  sx={{
                    padding: "12px 16px 12px 16px",
                    width: "120px",
                    height: "43px",
                    borderRadius: "6px",
                  }}
                  disableElevation
                  variant="contained"
                  disabled={!questions.length || addQuestion}
                  endIcon={<ArrowForwardIcon />}
                  onClick={() =>
                    navigate(
                      `/trial/${id}/survey/${surveyId}/${surveyVersionId}/preview`
                    )
                  }
                >
                  Next
                </Button>
              </Stack>
            </Stack>
          </Paper>

          {/* END: Body */}
        </Grid>
      </Grid>
    </Loading>
  );
}
