import { useEffect, useState } from "react";

import {
  Button,
  FormControlLabel,
  Stack,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import CustomDialog from "../../../components/CustomDialog";
import Checkbox from "@mui/material/Checkbox";
import RemoveIcon from "@mui/icons-material/Remove";
import { setBiomarkerKeys } from "../../../redux/biomarkers/biomarkerSlice";
import { containsObject, getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";

const DEFAULT_VALUE = false;

const getNestedDefaultValuedArray = (array, nestingKey) => {
  return array[nestingKey].map(() => DEFAULT_VALUE);
};

const getDefaultValuedArray = (array, nestingKey) => {
  return array.map((item) => getNestedDefaultValuedArray(item, nestingKey));
};

export default function DeleteBiomarker({
  categories,
  id,
  biomarkerCategories,
}) {
  const { palette } = useTheme();
  const [deleteBiomarkersDialog, setDeleteBiomarkersDialog] = useState(false);
  const [checked, setChecked] = useState(
    getDefaultValuedArray(categories, "biomarkers")
  );

  const biomarkerKeys = useSelector((state) => state.biomarkers.biomarkerKeys);

  useEffect(() => {
    setChecked(getDefaultValuedArray(categories, "biomarkers"));
  }, [categories, biomarkerCategories]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const handleCheckAll = () => {
    // to mark all biomarkers as checked or unchacked

    // checking if all biomarkers are already checked
    const allTrue = checked
      .map((i) => i.every((i) => i === true))
      .every((i) => i === true);

    // get updated checked array
    const updatedChecked = checked.map((item) => {
      // mapping through checked array

      // mapping through nested checked array
      // value is decided on the basis of `allTrue`
      return item.map(() => (allTrue ? false : true));
    });

    // setting updated value of `checked`
    setChecked(updatedChecked);
  };

  const handleCheckCategory = (category) => {
    // to mark all biomarkers as checked or unchacked on a particular category

    // checking if all biomarkers of the category are already checked
    const allTrue = checked[category.index].every((i) => i === true);

    // get updated checked array
    const updatedChecked = checked.map((item, index) => {
      // mapping through checked array
      if (index === category.index) {
        // when the category id found, values are updated
        // value is decided on the basis of `allTrue` for that particular category
        return checked[category.index].map(() => (allTrue ? false : true));
      }
      return item;
    });

    // setting updated value of `checked` for the category
    setChecked(updatedChecked);
  };

  const handleCheck = (category, biomarker) => {
    // to mark a biomarker as checked or unchacked

    // looping throuh all categories biomarkers
    const updatedChecked = checked.map((item, index) => {
      // when the categorie id found, to which the biomarker belongs
      if (index === category.index) {
        // looping through all biomarkers in that particular category
        return item.map((nestedItem, nestedIndex) => {
          // checking for the biomarker in the category it belongs to
          if (nestedIndex === biomarker.index) {
            // fliping the boolean value of `check`
            // returning the updated biomarker value
            return !nestedItem;
          }
          // returning the unchanged biomarker value
          return nestedItem;
        });
      }
      // returning the updated category
      return item;
    });

    // setting the updated `checked` array
    setChecked(updatedChecked);
  };

  const handleDelete = async () => {
    // generate table, by calling API

    setLoading(true);
    setError(null);

    let biomarkers = [];
    let biomarker_ids = [];
    // get name of the biomarkers that are checked
    for (var i = 0; i < checked.length; i++) {
      // loop through checked categories
      for (var j = 0; j < checked[i].length; j++) {
        // loop through biomarkers in categories
        if (checked[i][j]) {
          // if biomarker is checked, append it to the list
          biomarkers = [...biomarkers, categories[i]["biomarkers"][j]["name"]];
          biomarker_ids = [
            ...biomarker_ids,
            categories[i]["biomarkers"][j]["id"],
          ];
        }
      }
    }

    if (!biomarkers.length) {
      setError("Please select atleast 1 biomarker");
      setLoading(false);
      return;
    }

    // Make Axios request to get table data

    setLoading(false);

    try {
      await axios({
        method: "DELETE",
        url: `${DOMAIN}/biomarkers/`,
        params: {
          ids: JSON.stringify(biomarker_ids),
          trial_id: id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      dispatch(
        setBiomarkerKeys(
          biomarkerKeys.filter((i) => !containsObject(i.id, biomarker_ids))
        )
      );
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while adding biomarker");
      }
    }
  };

  return (
    <>
      <Button
        variant="conatained"
        sx={{
          backgroundColor: palette.primary[200],
          color: palette.primary[500],
        }}
        onClick={() => setDeleteBiomarkersDialog(true)}
        startIcon={<RemoveIcon />}
      >
        Delete Biomarker
      </Button>
      <CustomDialog
        open={deleteBiomarkersDialog}
        close={() => setDeleteBiomarkersDialog(false)}
        title="Delete Biomarkers"
        setOpen={setDeleteBiomarkersDialog}
        sx={{ paddingBottom: 0 }}
        content={
          <>
            <Stack>
              <FormControlLabel
                label={`All Biomarkers (${checked
                  .map((item) => item.length)
                  .reduce((i, j) => i + j, 0)})`}
                control={
                  <Checkbox
                    // checking if every biomarker in each category is checked
                    disabled
                    checked={checked
                      .map((i) => i.every((i) => i === true))
                      .every((i) => i === true)}
                    onChange={handleCheckAll}
                  />
                }
              />

              <Stack>
                {categories.map((category, index) => (
                  // looping through category array
                  <div key={index}>
                    <FormControlLabel
                      label={`${category.name}(${category.biomarkers.length})`}
                      control={
                        <Checkbox
                          // checking if every biomarker in this category is checked
                          disabled={!category.editable}
                          checked={
                            Boolean(
                              checked[category.index] &&
                                checked[category.index].length
                            ) &&
                            checked[category.index].every((i) => i === true)
                          }
                          onChange={() => handleCheckCategory(category)}
                        />
                      }
                    />
                    <Box
                      sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                    >
                      {category.biomarkers.map((biomarker, subIndex) => (
                        // looping through biomarker array in this category
                        <FormControlLabel
                          key={subIndex}
                          label={`${biomarker.name} (${biomarker.description})`}
                          control={
                            <Checkbox
                              // checking if this biomarker in checked
                              disabled={!biomarker.editable}
                              checked={checked[category.index][biomarker.index]}
                              onChange={() => handleCheck(category, biomarker)}
                            />
                          }
                        />
                      ))}
                    </Box>
                  </div>
                ))}
              </Stack>
            </Stack>
            {error ? (
              <>
                <br />
                <Typography variant="body2" sx={{ color: palette.error.main }}>
                  {error}
                </Typography>
                <br />
              </>
            ) : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                position: "sticky",
                bottom: "0",
                backgroundColor: palette.neutral[100],
                padding: "10px 10px",
              }}
            >
              <div></div>
              <div>
                <Button
                  fullWidth
                  disabled={loading}
                  onClick={handleDelete}
                  disableElevation
                  variant="contained"
                  sx={{
                    backgroundColor: palette.error.main,
                    padding: "12px 32px 12px 32px",
                    borderRadius: "6px",
                    "&:hover": {
                      background: palette.error.main,
                    },
                  }}
                >
                  {loading ? "Deleting" : "Delete"}
                </Button>
              </div>
            </Box>
          </>
        }
      />
    </>
  );
}
