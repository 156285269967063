// 3rd party modules
import { Link } from "react-router-dom";

// material ui hooks
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { logout } from "../redux/auth/authSlice";
import { setNotify } from "../redux/utils/utilsSlice";

// material ui modules
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

// utils

import urls from "../utils/urls.json";

// images
import trial_icon from "../assets/sidebar/trial-icon.svg";
import profile_icon from "../assets/sidebar/profile-icon.svg";
import contact_icon from "../assets/sidebar/contact-icon.svg";
import poweredby_logo from "../assets/sidebar/poweredby.svg";
import logo from "../assets/logo/UpdatedHubLogo.svg";

const sidebar_links = [
  {
    name: "Trials",
    code: "trials",
    icon: (
      <img
        src={trial_icon}
        alt="trial-icon"
        style={{ marginRight: "14px", width: "24px" }}
      />
    ),
    url: urls.home.path,
  },
  {
    name: "Profile",
    code: "profile",
    icon: (
      <img
        src={profile_icon}
        alt="profile-icon"
        style={{ marginRight: "14px", width: "24px" }}
      />
    ),
    url: urls.profile.path,
  },

  {
    name: "Contact Us",
    code: "contact",
    icon: (
      <img
        src={contact_icon}
        alt="contact-icon"
        style={{ marginRight: "10px", width: "28px" }}
      />
    ),
    url: urls.contact.path,
  },
];

export default function SideBar({ drawerWidth, open, setOpen, children }) {
  const auth = useSelector((state) => state.auth);
  const utils = useSelector((state) => state.utils);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { palette } = useTheme();
  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { lg: drawerWidth },
          minHeight: "100vh",
        }}
        className="Sidebar-Component"
      >
        <Drawer
          variant={matches ? "permanent" : "temporary"}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,

              background: "#00162F",
            },
          }}
          open={matches ? open : !open}
          onClose={() => setOpen(!open)}
        >
          <Box
            sx={{
              width: "80%",
              mx: "auto",
              pt: "50px",
            }}
          >
            <Typography align="center">
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "50%",
                  maxWidth: "90px",
                }}
              />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexFlow: "nowrap column",
              justifyContent: "space-between",
              height: "calc(100vh - 112px)",
            }}
          >
            <Stack
              sx={{ mt: "32px" }}
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              gap="24px"
            >
              {sidebar_links.map((element, index) => (
                <Box
                  sx={{
                    width: "100%",
                    paddingX: "16px",
                  }}
                  key={`sidebar-element-${index}`}
                >
                  <MenuItem
                    component={Link}
                    to={element.url}
                    sx={{
                      width: "100%",
                      padding: "0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        "&:hover": {
                          backgroundColor: palette.primary[700],
                        },
                        p: "12px 24px",
                        width: "100%",
                        borderRadius: "6px",

                        backgroundColor:
                          utils.activeTab === element.code
                            ? palette.primary[700]
                            : "transparent",
                      }}
                    >
                      {element.icon}
                      <Typography
                        variant="c18px600"
                        sx={{
                          color: palette.neutral[100],
                        }}
                        noWrap
                      >
                        {element.name}
                      </Typography>
                    </Box>
                  </MenuItem>
                </Box>
              ))}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Stack width="100%" spacing={2}>
                <Stack justifyContent="center" alignItems="center">
                  <img src={poweredby_logo} alt="logo" height="32px" />
                </Stack>
                {auth.isAuthenticated ? (
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // logout on click
                      dispatch(logout());

                      // notify
                      dispatch(
                        setNotify({
                          open: true,
                          action: "Logged out Successfully!",
                          severity: "success",
                          autoHideDuration: 5000,
                          vertical: "bottom",
                          horizontal: "right",
                        })
                      );

                      // redirect to login page
                      navigate(urls.login.path);
                    }}
                  >
                    <PowerSettingsNewIcon
                      fontSize="16px"
                      style={{
                        color: palette.neutral[100],
                        cursor: "pointer",
                      }}
                    />
                    <Typography
                      variant="c16px600"
                      sx={{
                        color: palette.neutral[100],
                      }}
                      noWrap
                    >
                      Logout
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}
