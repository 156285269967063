import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { CustomTextField } from "../../../components/CustomTextField";
import AddIcon from "@mui/icons-material/Add";
import Option from "./Option";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { Close } from "@mui/icons-material";

export default function Question({
  question,
  index,
  editQuestion,
  reorderQuestions,
  question_types,
  questions,
  setQuestions,
  isPreview,
  swapping,
  addOption,
  setAddOption,
  addQuestion,
  setAddQuestion,
}) {
  const { palette } = useTheme();
  const [questionTitle, setQuestionTitle] = useState(question.title);

  const [showOptions, setShowOptions] = useState(
    question.question_type === "mcq" ||
      question.question_type === "scq" ||
      false
  );
  const [isMcq, setIsMcq] = useState(question.question_type === "mcq" || false);

  const handleAddOption = async (e) => {
    e.preventDefault();
    setAddOption(true);
    const res = await axios({
      method: "POST",
      url: `${DOMAIN}/surveys/versions/questions/options/`,
      data: {
        title: "Enter Option",
        question: question.id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    });
    setQuestions(
      questions.map((q) => {
        if (q.id === question.id) {
          return {
            ...q,
            options: [...q.options, res.data],
          };
        }
        return q;
      })
    );
    setAddOption(false);
  };

  const updateOption = async (id, updatedOption) => {
    const res = await axios({
      method: "PATCH",
      url: `${DOMAIN}/surveys/versions/questions/options/`,
      params: {
        id,
      },
      data: updatedOption,
      headers: {
        Authorization: getAuthorization(),
      },
    });
    setQuestions(
      questions.map((q) => {
        if (q.id === question.id) {
          return {
            ...q,
            options: q.options.map((op) => {
              if (op.id === id) {
                return res.data;
              }
              return op;
            }),
          };
        }
        return q;
      })
    );
  };

  const removeOption = async (id) => {
    await axios({
      method: "DELETE",
      url: `${DOMAIN}/surveys/versions/questions/options/`,
      params: {
        id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    });
    window.location.reload();
  };

  const removeQuestion = async (id) => {
    await axios({
      method: "DELETE",
      url: `${DOMAIN}/surveys/versions/questions/`,
      params: {
        id,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    });
    window.location.reload();
  };
  const handleQuestionUpdate = (e) => {
    editQuestion(questionTitle, question, "changeTitle");
  };

  const handleChange = async (event) => {
    editQuestion(event.target.value, question, "changeQuestionType");
    switch (event.target.value) {
      case question_types[2].value:
        setIsMcq(true);
        setShowOptions(true);
        break;
      case question_types[3].value:
        setIsMcq(false);
        setShowOptions(true);
        break;
      default:
        setShowOptions(false);
        break;
    }
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "688px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          width: "100%",
        }}
        spacing={1}
      >
        {isPreview ? null : (
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
          >
            <Paper
              sx={{
                height: "32px",
                width: "32px",
              }}
              elevation={0}
            >
              <IconButton
                onClick={() => reorderQuestions(question, -1)}
                disabled={swapping || question.order_id === 1}
              >
                <ArrowUpwardIcon
                  sx={{
                    color:
                      swapping || question.order_id === 1
                        ? "inherit"
                        : palette.neutral[900],
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Paper>
            <Paper
              sx={{
                height: "32px",
                width: "32px",
              }}
              elevation={0}
            >
              <IconButton
                onClick={() => reorderQuestions(question, 1)}
                disabled={
                  swapping || questions[questions.length - 1] === question
                }
              >
                <ArrowDownwardIcon
                  sx={{
                    color:
                      swapping || questions[questions.length - 1] === question
                        ? "inherit"
                        : palette.neutral[900],
                  }}
                  fontSize="small"
                />
              </IconButton>
            </Paper>
          </Stack>
        )}

        <Paper
          elevation={0}
          sx={{
            height: "100%",
            padding: "24px 24px 24px 24px",
            width: "100%",
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={3}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Typography
                variant="c20px600"
                sx={{
                  color: palette.neutral[900],
                }}
              >
                Q{index + 1}
              </Typography>{" "}
              {!isPreview && (
                <IconButton
                  onClick={() => removeQuestion(question.id)}
                  size="small"
                >
                  <Close fontSize="small" color={palette.neutral[700]} />
                </IconButton>
              )}
            </Stack>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <FormControl fullWidth>
                <InputLabel
                  variant="standard"
                  style={{
                    marginTop: "-25px",
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="c14px400"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[500],
                      }}
                    >
                      {isPreview
                        ? "Question"
                        : "Enter a question for your trial subjects"}
                    </Typography>
                  </Stack>
                </InputLabel>
                <CustomTextField
                  fullWidth
                  required
                  variant="filled"
                  placeholder="Enter Question"
                  type="text"
                  value={questionTitle}
                  disabled={isPreview}
                  onChange={
                    isPreview ? null : (e) => setQuestionTitle(e.target.value)
                  }
                  InputProps={{ disableUnderline: true }}
                  onFocus={(e) => {
                    e.target.select();
                    setAddQuestion(true);
                  }}
                  onBlur={() => {
                    setAddQuestion(false);
                    handleQuestionUpdate();
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  variant="standard"
                  style={{
                    marginTop: "-25px",
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="c14px400"
                      style={{
                        textAlign: "left",
                        color: palette.neutral[500],
                      }}
                    >
                      {isPreview
                        ? "Answer Type"
                        : "How do you want the subjects to answer"}
                    </Typography>
                  </Stack>
                </InputLabel>
                <CustomTextField
                  select
                  value={question.question_type}
                  onChange={isPreview ? null : handleChange}
                  fullWidth
                  disabled={
                    isPreview ||
                    question.question_type === "mcq" ||
                    question.question_type === "scq"
                  }
                >
                  {question_types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </FormControl>

              {showOptions ? (
                isMcq ? (
                  <>
                    {question &&
                      question.options &&
                      question.options
                        .sort((a, b) => a.id - b.id)
                        .map((option, index) => (
                          <Option
                            key={index}
                            isPreview={isPreview}
                            option={option}
                            updateOption={updateOption}
                            removeOption={removeOption}
                            setAddOption={setAddOption}
                            setAddQuestion={setAddQuestion}
                            addQuestion={addQuestion}
                          />
                        ))}
                    {!isPreview && (
                      <Button
                        disabled={addOption}
                        onClick={handleAddOption}
                        fullWidth
                        disableElevation
                        startIcon={<AddIcon />}
                        sx={{
                          width: "100%",
                          backgroundColor: palette.primary[200],
                          color: palette.primary[500],
                          height: "41px",
                        }}
                      >
                        Option
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {question &&
                      question.options &&
                      question.options
                        .sort((a, b) => a.id - b.id)
                        .map((option, index) => (
                          <Option
                            key={index}
                            option={option}
                            updateOption={updateOption}
                            removeOption={removeOption}
                            setAddOption={setAddOption}
                            singleChoice
                            isPreview={isPreview}
                            setAddQuestion={setAddQuestion}
                            addQuestion={addQuestion}
                          />
                        ))}
                    {!isPreview && (
                      <Button
                        disabled={addOption}
                        onClick={handleAddOption}
                        fullWidth
                        disableElevation
                        startIcon={<AddIcon />}
                        sx={{
                          width: "100%",
                          backgroundColor: palette.primary[200],
                          color: palette.primary[500],
                          height: "41px",
                        }}
                      >
                        Option
                      </Button>
                    )}
                  </>
                )
              ) : null}
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
}
