import { Search } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/Blockers/Loading";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { StyledStat, StyledSubtitle } from "./OverviewStats";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "0px",
    fontSize: theme.typography.fontSize.md,
    fontWeight: theme.typography.fontWeightRegular,
    padding: "10px 12px",
  },
  overflow: "hidden",
  borderRadius: "6px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(16),
  fontSize: theme.typography.fontSize.md,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.neutral[700],
}));

export default function Biomarkers({ trialId }) {
  const [biomarkers, setBiomarkers] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const { palette, typography, spacing } = useTheme();

  const fetchBiomarkers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: `${DOMAIN}/biomarkers/overview/stats/`,
        params: {
          trial_id: trialId,
          search: searchQuery,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      setBiomarkers({
        ...res.data.biomarkers,
        ...res.data.other_biomarkers,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [trialId, searchQuery]);

  useEffect(() => {
    fetchBiomarkers();
  }, [fetchBiomarkers]);

  return (
    <Stack
      padding={3}
      spacing={2}
      sx={{
        backgroundColor: palette.neutral[100],
        borderRadius: "6px",
        border: `1px solid  ${palette.neutral[300]}`,
      }}
    >
      <Typography
        variant="c18px600"
        lineHeight="normal"
        color={palette.neutral[900]}
      >
        Biomarkers
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <StyledSubtitle>Total Biomarkers</StyledSubtitle>
          <StyledStat>{Object.keys(biomarkers).length}</StyledStat>
        </Stack>
        <FormControl>
          <StyledTextField
            fullWidth
            variant="filled"
            placeholder="Search Biomarkers"
            sx={{
              fontSize: typography.fontSize.md,
              fontWeight: typography.fontWeightRegular,
              lineHeight: "normal",
            }}
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Stack>

      <Loading loading={loading}>
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <StyledTableCell align="right">Min</StyledTableCell>
                <StyledTableCell align="right">Max</StyledTableCell>
                <StyledTableCell align="right">Average</StyledTableCell>
                <StyledTableCell align="right">Median</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(biomarkers)?.map((biomarkerName) => {
                const biomarker = biomarkers[biomarkerName];
                return (
                  <TableRow key={biomarkerName}>
                    <TableCell
                      sx={{
                        fontSize: typography.fontSize.md,
                        fontWeight: typography.fontWeightMedium,
                        color: palette.neutral[700],
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: spacing(24),
                      }}
                    >
                      {biomarkerName} ({biomarker.description})
                    </TableCell>
                    <StyledTableCell align="right">
                      {biomarker.low}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {biomarker.high}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {biomarker.mean}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {biomarker.median}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Loading>
    </Stack>
  );
}
