import { ArrowForward } from "@mui/icons-material";
import { Button, IconButton, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShareTrial from "./ShareTrial";
import { StyledTooltip } from "../../../components/CustomTooltip";
import { ReactComponent as BarChartIcon } from "../../../assets/trial/bar-chart.svg";

// NOTE: TrialMoreOption component is button on Trial page with Survey button
export default function TrialMoreOption({ trialId, isOwner }) {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <StyledTooltip title="Trial Overview">
        <IconButton
          sx={{
            backgroundColor: palette.neutral[200],
            padding: "8px",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: palette.neutral[300],
            },
            "&:clicked": {
              border: "1px solid #BCBCBC",
            },
          }}
          onClick={() => {
            navigate(`/trial/${trialId}/overview`);
          }}
        >
          <BarChartIcon />
        </IconButton>
      </StyledTooltip>
      {isOwner ? <ShareTrial id={trialId} /> : null}
      <Button
        data-cy="button__surveys"
        disableElevation
        onClick={() => {
          navigate(`/trial/${trialId}/survey`);
        }}
        endIcon={<ArrowForward sx={{ color: palette.neutral[100] }} />}
        variant="contained"
        sx={{
          backgroundColor: palette.primary[500],
          color: palette.neutral[100],
          borderRadius: "4px",
          fontSize: "16px",
          fontWeight: 500,
        }}
      >
        Surveys
      </Button>
    </Stack>
  );
}
