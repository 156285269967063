import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setActiveTab, setNotify } from "../../../redux/utils/utilsSlice";

import {
  Container,
  Typography,
  Stack,
  FormControl,
  Button,
  MenuItem,
  useTheme,
} from "@mui/material";

import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";

import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import urls from "../../../utils/urls.json";

const GENDERS = [
  {
    label: "Male",
    value: "m",
  },
  {
    label: "Female",
    value: "f",
  },
];

export default function ActivateSession() {
  const { palette } = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [secretId, setSecretId] = useState("");
  const [gender, setGender] = useState("");
  const [yob, setYob] = useState("");

  const [searchParams] = useSearchParams();
  const sessionKey = searchParams.get("qr_code");
  const device_fcm_token = searchParams.get("device_fcm_token");

  // redux
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActiveTab("profile"));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    try {
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/trials/sessions/activate/`,
        params: {
          session_key: sessionKey,
          device_fcm_token,
        },
        data: {
          secret_id: secretId,
          gender,
          yob,
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully Activated Session",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      setLoading(false);

      setSecretId("");
      setGender("");
      setYob("");

      try {
        const appData = {
          qr_code: sessionKey,
          total_tests: response.data.activation_data.total_tests,
          status: response.data.activation_data.status,
          validity: response.data.activation_data.validity,
          tests_remaining: response.data.activation_data.tests_remaining,
        };
        window.localStorage.setItem("appData", JSON.stringify(appData));
        // redirect
        navigate(urls.sessionActivated.path);
      } catch {
        alert("Unable to redirect to HUB App, please open it manually.");
      }
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while Activating Session");
      }
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "48px 20px",
        backgroundColor: palette.primary[100],
        height: "100vh",
      }}
    >
      <Typography
        variant="c32px600"
        style={{
          textAlign: "center",
        }}
      >
        Session Activation
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            paddingTop: "90px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Stack my={2} direction="column" spacing={3}>
            <FormControl>
              <CustomInputLabel label="Secret Key" />
              <CustomTextField
                required
                fullWidth
                helperText="Secret Key is unique 4 to 10 characters long, must be provided to you."
                variant="filled"
                placeholder="xxxxxx"
                type="text"
                value={secretId}
                onChange={(e) => setSecretId(e.target.value)}
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <FormControl>
              <CustomInputLabel label="Gender" />
              <CustomTextField
                required
                fullWidth
                helperText="Please select your gender"
                variant="filled"
                placeholder="eg. Male"
                select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                InputProps={{ disableUnderline: true }}
              >
                {GENDERS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            </FormControl>
            <FormControl>
              <CustomInputLabel label="Year Of Birth" />
              <CustomTextField
                error={error ? true : false}
                helperText={error}
                required
                fullWidth
                variant="filled"
                placeholder="eg. 1980"
                type="number"
                value={yob}
                onChange={(e) => setYob(e.target.value)}
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <Button
              disabled={loading}
              disableElevation
              type="submit"
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? "Submitting" : "Submit"}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
