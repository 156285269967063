import { useState } from "react";
import * as React from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../redux/utils/utilsSlice";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import urls from "../../utils/urls.json";
import { saveUserCredentials } from "../../utils/helpers";
import { DOMAIN } from "../../utils/config";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField as TextFieldBase,
  Typography,
  useTheme,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// CAROUSEL
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import carousel1 from "../../assets/carousel1.svg";
import carousel2 from "../../assets/carousel2.svg";
import carousel3 from "../../assets/carousel3.svg";
import { isValidEmail } from "../../utils/emailValid";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    heading: "START TRIALS EASILY",
    description: null,
    imgPath: carousel1,
  },
  {
    heading: "ACCESS ALL YOUR TRIALS AT ONE PLACE",
    description: null,
    imgPath: carousel2,
  },
  {
    heading: "DOWNLOAD SOPS AND SUBJECT INFO TABLE AT EASE",
    description: null,
    imgPath: carousel3,
  },
];

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

export default function Login() {
  const { palette } = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // CAROUSEL
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);

  // redux
  const dispatch = useDispatch();

  // react-router-dom navigator
  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setErrorEmail(null);
    setErrorPassword(null);
    // set loading to true
    setLoading(true);

    if (!email) {
      setErrorEmail("Email is required");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setErrorEmail("Email is not valid");
      setLoading(false);
      return;
    }

    if (!password) {
      setErrorPassword("Password is required");
      setLoading(false);
      return;
    }

    try {
      // make API request
      const response = await axios({
        method: "POST",
        url: `${DOMAIN}/auth/login/`,
        data: {
          email,
          password,
        },
      });
      // save user credentials
      saveUserCredentials(response.data);
      // redirect to home page

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Logged in Successfully!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigator(urls.home.path);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        // set error
        setErrorEmail(error_object.non_field_errors[0]);
        setErrorPassword(error_object.non_field_errors[0]);
      } catch {
        // default error message
        setErrorEmail("Unable to log in with provided credentials");
        setErrorPassword("Unable to log in with provided credentials");
      }
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setErrorEmail(null);
    setErrorPassword(null);
    func(value);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          px={2}
          style={{
            height: "100vh",
            backgroundColor: palette.neutral[100],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            py={5}
            style={{
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography
              variant="c32px600"
              style={{
                color: palette.neutral[900],
              }}
              data-cy="page__title"
            >
              Log In
            </Typography>
            <Typography
              variant="c20px500"
              sx={{
                color: palette.neutral[900],
              }}
              data-cy="page__greeting"
            >
              Welcome back!
            </Typography>
            <Stack my={2} direction="column" spacing={2}>
              <FormControl>
                <InputLabel shrink variant="standard">
                  Enter Your Email ID
                </InputLabel>
                <TextField
                  data-cy="input__email"
                  error={errorEmail ? true : false}
                  helperText={errorEmail}
                  fullWidth
                  variant="filled"
                  placeholder="Email ID"
                  type="email"
                  value={email}
                  onChange={(e) => handleChange(setEmail, e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              <FormControl>
                <InputLabel shrink variant="standard">
                  Enter Your Password
                </InputLabel>
                <TextField
                  data-cy="input__password"
                  error={errorPassword ? true : false}
                  helperText={errorPassword}
                  fullWidth
                  variant="filled"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => handleChange(setPassword, e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              <Typography
                variant="c16px400"
                data-cy="link__forgotPassword"
                component={Link}
                to={urls.forgotPassword.path}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  color: palette.primary[500],
                }}
              >
                Forgot Password?
              </Typography>
              <Button
                data-cy="button__login"
                type="submit"
                disableElevation
                disabled={loading}
                size="large"
                color="primary"
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={handleSubmit}
              >
                {loading ? "Logging In" : "Log In"}
              </Button>
            </Stack>
            <Typography
              style={{
                fontSize: "16px",
                textAlign: "center",
                borderBottom: "1px solid #CDCDCD",
                lineHeight: "0.1em",
                margin: "10px 0 20px",
              }}
            >
              <span
                style={{
                  background: "#fff",
                  padding: "0 10px",
                  color: palette.neutral[900],
                }}
              >
                OR
              </span>
            </Typography>
            <Button
              data-cy="link__register"
              component={Link}
              to={urls.signup.path}
              disableElevation
              size="large"
              color="primary"
              variant="outlined"
              style={{ textTransform: "none" }}
            >
              Register
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        style={{
          backgroundColor: palette.primary[700],
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ backgroundColor: "transparent" }}
            />
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                variant="c16px600"
                style={{
                  color: palette.neutral[100],
                  textAlign: "left",
                }}
              >
                {images[activeStep].heading}{" "}
              </Typography>
              <Typography
                variant="c14px500"
                style={{
                  color: palette.neutral[100],
                  textAlign: "left",
                }}
              >
                {images[activeStep].description}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
