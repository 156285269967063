// Save User Credentials
export const saveUserCredentials = (data) => {
  // helper function to save user credentials to the local storage
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  localStorage.setItem("user", JSON.stringify(data.user));
};

// Delete User Credentials
export const deleteUserCredentials = (data) => {
  // helper function to delete user credentials from local storage
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
};

// Get User Credentials
export const getUserCredentials = () => {
  // helper function to fetch user credentials from local storage
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = {};
  }
  return {
    accessToken: localStorage.getItem("access_token"),
    refreshToken: localStorage.getItem("refresh_token"),
    user,
  };
};

export const getAuthorization = () => {
  const data = getUserCredentials();
  return `Bearer ${data["accessToken"]}`;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "win";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return null;
};

export const containsObject = (obj, list) => {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
};

export const anyValueTrue = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      return true;
    }
  }
  return false;
};
