import { Container, Typography, Button, Stack, useTheme } from "@mui/material";

export default function SessionActivated() {
  const { palette } = useTheme();
  const handleOpenApp = () => {
    let appData = window.localStorage.getItem("appData");
    let url = `hub://${appData}`;
    console.log(url);
    window.location.replace(url);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "48px 20px",
        backgroundColor: palette.primary[100],
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Stack direction="column">
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            color: palette.success.main,
          }}
        >
          Session Activated
        </Typography>
        <br />
        <Button
          disableElevation
          onClick={handleOpenApp}
          variant="contained"
          size="small"
        >
          Open HUB App
        </Button>
      </Stack>
    </Container>
  );
}
