import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Radio,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { CustomInput } from "../../../components/CustomTextField";

export default function Option({
  isPreview,
  option,
  updateOption,
  removeOption,
  singleChoice,
  setAddOption,
  setAddQuestion,
  addQuestion,
}) {
  const [optionTitle, setOptionTitle] = useState(option.title);
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: palette.neutral[200],
        borderRadius: "6px",
        padding: "0px 12px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {singleChoice ? (
          <CustomInput
            fullWidth
            type="text"
            disableUnderline={true}
            value={optionTitle}
            disabled={isPreview}
            onChange={isPreview ? null : (e) => setOptionTitle(e.target.value)}
            onFocus={(e) => {
              e.target.select();
              setAddOption(true);
              setAddQuestion(true);
              console.log("....");
            }}
            startAdornment={
              <Radio
                sx={{ color: "action.active", my: 0.5, cursor: "pointer" }}
              />
            }
            onBlur={() => {
              setAddOption(false);
              setAddQuestion(false);
              if (!isPreview) {
                updateOption(option.id, {
                  ...option,
                  title: optionTitle,
                });
              }
            }}
          />
        ) : (
          <CustomInput
            fullWidth
            type="text"
            disableUnderline={true}
            value={optionTitle}
            disabled={isPreview}
            onChange={isPreview ? null : (e) => setOptionTitle(e.target.value)}
            onFocus={(e) => {
              e.target.select();
              setAddOption(true);
              setAddQuestion(true);
            }}
            onBlur={() => {
              setAddOption(false);
              setAddQuestion(false);

              if (!isPreview) {
                updateOption(option.id, {
                  ...option,
                  title: optionTitle,
                });
              }
            }}
            startAdornment={<Checkbox />}
          />
        )}
        {!isPreview && (
          <IconButton onClick={() => removeOption(option.id)} size="small">
            <Close fontSize="small" color={palette.neutral[700]} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
}
