import {
  Grid,
  Stack,
  Typography,
  Button,
  MenuItem,
  Dialog,
  Tab,
  Tabs,
  Menu,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  FiberManualRecord,
  FlashOnOutlined,
  FlashOffOutlined,
  CalendarToday,
} from "@mui/icons-material";
import { CustomTextField } from "../../../components/CustomTextField";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Question from "../Surveys/Question";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthorization } from "../../../utils/helpers";
import Loading from "../../../components/Blockers/Loading";
import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";
import ResponseTable from "./ResponseTable";
import EditSchedule from "./EditSchedule";

export const question_types = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "mcq",
    label: "Multiple choice (Checkbox)",
  },
  {
    value: "scq",
    label: "Single choice (Radio-button)",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

/////////Tab component///////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function SurveyVersion() {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openEditScheduleDialog, setOpenEditScheduleDialog] = useState(false);
  const [openSetTimingDialog, setOpenSetTimingDialog] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { surveyVersionId, surveyId, id } = useParams();
  const [surveyVersion, setSurveyVersion] = useState({});
  const [versions, setVersions] = useState([]);
  const [loadingSurveyVersion, setLoadingSurveyVersion] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  console.log(error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStatus = (surveyVersion) => {
    switch (surveyVersion.status) {
      case "active":
        return {
          text: "Active",
          color: palette.success.main,
        };
      case "inactive":
        return {
          text: "Inactive",
          color: palette.neutral[500],
        };
      default:
        return {
          text: "Saved",
          color: palette.primary[500],
        };
    }
  };

  const getSchedule = (surveyVersion) => {
    switch (surveyVersion.timing) {
      case "BEFORE_TEST":
        return {
          text: "Before Test",
          color: palette.success.main,
          title: "This test would appear before the test starts.",
        };
      case "AFTER_TEST":
        return {
          text: "After Test",
          color: palette.neutral[500],
          title: "This test would appear after the test ends.",
        };
      default:
        return {
          text: "Custom Timing",
          color: palette.primary[500],
          title: `Start Date: ${surveyVersion.metadata.custom_timing.start_date}    `,
        };
    }
  };

  const handleSubmit = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/surveys/versions/${
          surveyVersion.status === "active" ? "deactivate" : "activate"
        }`,
        data: {
          id: surveyVersionId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: `Survey ${
            surveyVersion.status === "active" ? "Deactivated" : "Activated"
          }`,
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
      window.location.reload();
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  const handleEdit = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      const res = await axios({
        method: "POST",
        url: `${DOMAIN}/surveys/versions/clone/`,
        data: {
          id: surveyVersionId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      navigate(
        `/trial/${id}/survey/${surveyId}/${res.data.id}/edit?utm_source=${surveyVersion.status}`
      ); // TODO: correct this
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 3000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  const handleDelete = async () => {
    // remove errors from state, if any
    setError(null);
    try {
      // make API request
      await axios({
        method: "DELETE",
        url: `${DOMAIN}/surveys/versions/`,

        data: {
          id: surveyVersionId,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Survey Deleted",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate(`/trial/${id}/survey/`);
    } catch (err) {
      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/versions/questions/`,
      params: {
        version_id: surveyVersionId,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      setQuestions(res.data.payload);
      setLoadingQuestions(false);
    });
  }, [surveyVersionId]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/surveys/versions/`,
      params: {
        id: surveyVersionId,
        trial_id: id,
        survey_id: surveyId,
        deep: true,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    }).then((res) => {
      setSurveyVersion(res.data.payload[0]);
      setVersions(res.data.payload[0].survey.versions);
      setLoadingSurveyVersion(false);
    });
  }, [id, surveyId, surveyVersionId]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const OpenEditSchedule = () => {
    setOpenEditScheduleDialog(true);
  };
  const OpenSetTiming = () => {
    setOpenSetTimingDialog(true);
  };
  const OpenDeactivateDialog = () => {
    setOpenDeactivateDialog(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenDeleteDialog(false);
    setOpenDeactivateDialog(false);
    setOpenEditScheduleDialog(false);
    setOpenSetTimingDialog(false);
  };
  const OpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  return (
    <Grid
      container
      style={{
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {loadingSurveyVersion || loadingQuestions ? (
        <Loading loading={true} />
      ) : (
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              // component={Paper}
              style={{
                position: "sticky",
                top: "0",
                zIndex: 101,
                height: "102px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
                backgroundColor: palette.neutral[100],
              }}
            >
              {/* START: First Row */}

              <Stack
                px={{ xs: "32px", ll: "48px" }}
                py="24px"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="column"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Link key="1" to={`/trial/${id}/survey`}>
                      <Typography
                        variant="c16px500"
                        sx={{
                          color: palette.neutral[500],
                        }}
                      >
                        All Surveys
                      </Typography>
                    </Link>
                    <Typography
                      variant="c16px500"
                      sx={{
                        color: palette.neutral[500],
                      }}
                    >
                      /
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="c18px600"
                        style={{
                          textAlign: "left",
                          color: palette.neutral[900],
                        }}
                      >
                        {surveyVersion.survey.title}
                      </Typography>
                      {surveyVersion.status === "saved" ? null : (
                        <Box
                          sx={{
                            height: "32px",
                            width: "56px",
                          }}
                        >
                          <CustomTextField
                            select
                            variant="filled"
                            defaultValue={surveyVersion.id || ""}
                            onChange={(e) => {
                              window.location.replace(
                                `/trial/${id}/survey/${surveyId}/${e.target.value}`
                              );
                            }}
                          >
                            {versions.map((option, index) => (
                              <MenuItem key={index} value={option.id}>
                                v{option.version_number}
                              </MenuItem>
                            ))}
                          </CustomTextField>
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={0.3}
                    >
                      <FiberManualRecord
                        fontSize="small"
                        sx={{
                          color: getStatus(surveyVersion).color,
                          height: "10px",
                          width: "10px",
                        }}
                      />
                      <Typography
                        variant="c16px600"
                        sx={{
                          textAlign: "left",
                          color: getStatus(surveyVersion).color,
                        }}
                      >
                        {getStatus(surveyVersion).text}
                      </Typography>
                    </Stack>
                    <Tooltip
                      title={getSchedule(surveyVersion).title}
                      arrow
                      placement="right"
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0.3}
                      >
                        <CalendarToday
                          sx={{
                            color: palette.neutral[700],
                            height: "12px",
                            width: "12px",
                          }}
                        />
                        <Typography
                          variant="c16px400"
                          sx={{
                            textAlign: "left",
                            color: palette.neutral[700],
                          }}
                        >
                          {getSchedule(surveyVersion).text}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    data-cy="button__editSurvey"
                    sx={{
                      color: palette.primary[500],
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      <EditOutlinedIcon
                        sx={{ color: palette.primary[500], fontSize: "14px" }}
                      />
                    }
                    variant="text"
                    id="basic-button"
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Edit
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    {surveyVersion.status === "saved" ? (
                      <MenuItem
                        data-cy="option__editSchedule"
                        sx={{
                          color: palette.neutral[500],
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        onClick={OpenEditSchedule}
                      >
                        Edit Schedule
                      </MenuItem>
                    ) : null}

                    <MenuItem
                      sx={{
                        color: palette.neutral[500],
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      onClick={handleClickOpen}
                    >
                      Edit Questionnaire & Schedule
                    </MenuItem>
                  </Menu>
                  <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Typography
                        variant="c18px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        Edit
                      </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography variant="c16px500" gutterBottom>
                        Are you sure you want to edit this survey version?
                      </Typography>
                      <Typography variant="c16px500" gutterBottom>
                        Editing this survey version would create another
                        version.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        endIcon={
                          <EditOutlinedIcon
                            sx={{ color: palette.neutral[100] }}
                          />
                        }
                        variant="contained"
                        autoFocus
                        onClick={() => {
                          if (
                            surveyVersion.status === "active" ||
                            surveyVersion.status === "inactive"
                          ) {
                            handleEdit();
                          } else {
                            navigate(
                              `/trial/${id}/survey/${surveyId}/${surveyVersionId}/edit?utm_source=${surveyVersion.status}`
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                  {/* START: Edit Schedule dialog */}
                  <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openEditScheduleDialog}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Typography
                        variant="c20px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        Edit Schedule
                      </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography variant="c16px500" gutterBottom>
                        Are you sure you want to edit schedule of this survey?
                      </Typography>
                      <Typography variant="c16px500" gutterBottom>
                        Editing this would result in change of survey schedule
                        and the subjects will participate in the survey
                        according to the edited schedule.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        data-cy="button__editSchedule"
                        endIcon={
                          <EditOutlinedIcon
                            sx={{ color: palette.neutral[100] }}
                          />
                        }
                        variant="contained"
                        autoFocus
                        onClick={OpenSetTiming}
                      >
                        Edit Schedule
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                  {/* END: Edit Schedule dialog */}
                  <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openSetTimingDialog}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Typography
                        variant="c20px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        Edit Schedule
                      </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent>
                      <EditSchedule surveyVersion={surveyVersion} />
                    </DialogContent>
                  </BootstrapDialog>
                  <Button
                    onClick={OpenDeactivateDialog}
                    sx={{
                      color:
                        surveyVersion.status === "active"
                          ? palette.neutral[500]
                          : "#00993E",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      surveyVersion.status === "active" ? (
                        <FlashOffOutlined
                          sx={{ color: palette.neutral[500], fontSize: "14px" }}
                        />
                      ) : (
                        <FlashOnOutlined
                          sx={{ color: palette.success.main, fontSize: "14px" }}
                        />
                      )
                    }
                    variant="text"
                  >
                    {surveyVersion.status === "active"
                      ? "Deactivate"
                      : "Activate"}
                  </Button>
                  <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDeactivateDialog}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Typography
                        variant="c18px600"
                        sx={{
                          color: palette.neutral[900],
                        }}
                      >
                        {surveyVersion.status === "active"
                          ? "Deactivate"
                          : "Activate"}
                      </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography variant="body1" gutterBottom>
                        Are you sure you want to{" "}
                        {surveyVersion.status === "active"
                          ? "Deactivate"
                          : "Activate"}{" "}
                        this survey version?
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Once deactivated, subjects would not be able to
                        participate in this survey version.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        disableElevation
                        endIcon={
                          surveyVersion.status === "active" ? (
                            <FlashOffOutlined
                              sx={{ color: palette.neutral[100] }}
                            />
                          ) : (
                            <FlashOnOutlined
                              sx={{ color: palette.neutral[100] }}
                            />
                          )
                        }
                        variant="contained"
                        autoFocus
                        onClick={handleSubmit}
                      >
                        {surveyVersion.status === "active"
                          ? "Deactivate"
                          : "Activate"}
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                  <Button
                    data-cy="button__deleteSurvey"
                    onClick={OpenDeleteDialog}
                    sx={{
                      color: palette.error.main,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    startIcon={
                      <DeleteOutlinedIcon
                        sx={{ color: palette.error.main, fontSize: "14px" }}
                      />
                    }
                    variant="text"
                  >
                    Delete
                  </Button>
                  <BootstrapDialog
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openDeleteDialog}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Typography
                        sx={{
                          color: palette.neutral[900],
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Delete
                      </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                      <Typography variant="c16px500" gutterBottom>
                        Are you sure you want to delete this survey version?
                      </Typography>
                      <Typography variant="c16px500" gutterBottom>
                        Once deleted, the survey and the responses collected
                        will be lost permanently.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        data-cy="modal__deleteSurvey"
                        onClick={handleDelete}
                        sx={{
                          color: palette.neutral[100],
                          fontSize: "14px",
                          fontWeight: "500",
                          backgroundColor: palette.error.main,
                        }}
                        startIcon={
                          <DeleteOutlinedIcon
                            sx={{
                              color: palette.neutral[100],
                              fontSize: "14px",
                            }}
                          />
                        }
                        variant="contained"
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </Stack>
              </Stack>

              {/* END: First Row */}
            </Stack>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt="34px"
          >
            <Box
              sx={{
                height: "33px",
                width: "240px",
                // backgroundColor: " #2D81E3",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  sx={{
                    borderRadius: "4px",
                    padding: "0px 24px",
                    fontWeight: "600",
                    backgroundColor: palette.primary[500],
                  }}
                  label={
                    <span style={{ color: palette.neutral[100] }}>
                      Questions
                    </span>
                  }
                />
                <Tab
                  sx={{
                    borderRadius: "4px",
                    padding: "0px 24px",
                    fontWeight: "600",
                    backgroundColor: palette.neutral[100],
                  }}
                  label={
                    <span style={{ color: palette.primary[500] }}>
                      Responses
                    </span>
                  }
                />
              </Tabs>
            </Box>
          </Stack>
          {/* <Box sx={{ width: "100%", typography: "body1" }}> */}
          <TabPanel value={value} index={0}>
            <Stack
              minHeight="90vh"
              width="100%"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              sx={{ padding: "32px 0px" }}
            >
              {/* START: Body */}
              {questions.length ? (
                questions
                  .sort((a, b) => a.order_id - b.order_id)
                  .map((question, index) => (
                    <Question
                      key={question.order_id}
                      index={index}
                      isPreview={true}
                      questions={questions}
                      question={question}
                      question_types={question_types}
                    />
                  ))
              ) : (
                <Typography align="center">No Questions</Typography>
              )}
              {/* END: Body */}
            </Stack>{" "}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ResponseTable />
          </TabPanel>
          {/* </Box> */}
        </Grid>
      )}
    </Grid>
  );
}
