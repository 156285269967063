import { createSlice } from "@reduxjs/toolkit";

export const biomarkerSlice = createSlice({
  name: "biomarkers",
  initialState: {
    loading: false,
    categories: [],
    selectedBiomarkerKeys: [],
    biomarkerKeys: [],
    values: [],
    checkedBiomarkersWithId: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSelectedBiomarkerKeys: (state, action) => {
      state.selectedBiomarkerKeys = action.payload;
    },
    setBiomarkerKeys: (state, action) => {
      state.biomarkerKeys = action.payload;
    },
    setValues: (state, action) => {
      state.values = action.payload;
    },
    setCheckedBiomarkersWithId: (state, action) => {
      // find the id of the biomarker
      const idIndex = state.checkedBiomarkersWithId.findIndex(
        (biomarker) => biomarker.id === action.payload.id
      );
      const updatedCheckedBiomarkers = [...state.checkedBiomarkersWithId];
      // if id is not found then adding the whole biomarker object
      if (idIndex === -1) {
        updatedCheckedBiomarkers.push({
          id: action.payload.id,
          checked: action.payload.checked,
        });
      } else {
        // else updating the checked biomarker
        updatedCheckedBiomarkers[idIndex] = {
          ...updatedCheckedBiomarkers[idIndex],
          checked: action.payload.checked,
        };
      }
      return {
        ...state,
        checkedBiomarkersWithId: updatedCheckedBiomarkers,
      };
    },
  },
});

export const {
  setLoading,
  setCategories,
  setSelectedBiomarkerKeys,
  setBiomarkerKeys,
  setValues,
  setCheckedBiomarkersWithId,
} = biomarkerSlice.actions;

export default biomarkerSlice.reducer;
