import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField as TextFieldBase,
  Typography,
  useTheme,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// CAROUSEL

import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import carousel1 from "../../assets/carousel1.svg";
import carousel2 from "../../assets/carousel2.svg";
import carousel3 from "../../assets/carousel3.svg";

import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../redux/utils/utilsSlice";

import axios from "axios";
import { DOMAIN } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    heading: "START TRIALS EASILY",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
    imgPath: carousel1,
  },
  {
    heading: "ACCESS ALL YOUR TRIALS AT ONE PLACE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
    imgPath: carousel2,
  },
  {
    heading: "DOWNLOAD SOPS AND SUBJECT INFO TABLE AT EASE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",

    imgPath: carousel3,
  },
];

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

export default function ResetPassword() {
  const { palette } = useTheme();
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const { uid, token } = useParams();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [errorPassword1, setErrorPassword1] = useState(null);
  const [errorPassword2, setErrorPassword2] = useState(null);

  // redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    // handle form submit
    e.preventDefault();

    // remove errors from state, if any
    setError(null);
    setErrorPassword1(null);
    setErrorPassword2(null);

    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/auth/password/reset/confirm/`,
        data: {
          uid: window.atob(uid),
          token,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
      });

      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Password changed!",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      navigate("/login");
    } catch (err) {
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);

        // set error
        setErrorPassword1(
          error_object.new_password1 && error_object.new_password1[0]
        );
        setErrorPassword2(
          error_object.new_password2 && error_object.new_password2[0]
        );
        setError(
          error_object.non_field_errors && error_object.non_field_errors[0]
        );
      } catch {
        // default error message
        setError(["Something Went Wrong!"]);
      }
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    setErrorPassword1(null);
    setErrorPassword2(null);
    func(value);
  };

  // CAROUSEL
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          px={2}
          style={{
            height: "100vh",
            backgroundColor: palette.neutral[100],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={3}
            py={5}
            style={{
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <Typography
              variant="c32px600"
              style={{
                textAlign: "left",
              }}
            >
              Password Reset
            </Typography>

            <form autoComplete="off" onSubmit={handleSubmit}>
              <Stack my={2} direction="column" spacing={2}>
                <FormControl>
                  <TextField
                    error={errorPassword1 ? true : false}
                    helperText={errorPassword1}
                    fullWidth
                    variant="filled"
                    placeholder="New Password"
                    type="text"
                    value={newPassword1}
                    onChange={(e) => {
                      handleChange(setNewPassword1, e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                    required
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    error={errorPassword2 ? true : false}
                    helperText={errorPassword2 || error}
                    fullWidth
                    variant="filled"
                    placeholder="Confirm Password"
                    type="text"
                    value={newPassword2}
                    onChange={(e) => {
                      handleChange(setNewPassword2, e.target.value);
                    }}
                    InputProps={{ disableUnderline: true }}
                    required
                  />
                </FormControl>

                <Button
                  type="submit"
                  disableElevation
                  disabled={loading}
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ textTransform: "none" }}
                >
                  Change Password
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        style={{
          backgroundColor: palette.primary[700],
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ backgroundColor: "transparent" }}
            />
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                variant="c16px600"
                style={{
                  color: palette.neutral[100],
                  textAlign: "left",
                }}
              >
                {images[activeStep].heading}{" "}
              </Typography>
              <Typography
                variant="c14px500"
                style={{
                  color: palette.neutral[100],
                  textAlign: "left",
                }}
              >
                {images[activeStep].description}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
