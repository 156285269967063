import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

import { Button, Dialog, FormControl, useTheme } from "@mui/material";
import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import AddIcon from "@mui/icons-material/Add";
import {
  CustomDialogActions,
  CustomDialogTitle,
  CustomDialogContent,
} from "../../../components/CustomDialog";
export default function AddSubjects(props) {
  const { palette } = useTheme();
  const [addSubjectsDialog, setAddSubjectsDialog] = useState(false);
  const [addSubjectsCount, setAddSubjectsCount] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // redux
  const dispatch = useDispatch();

  const handleAddSubject = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/trials/subjects/`,
        params: {
          id: props.id,
          count: addSubjectsCount,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully added subject(s)",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      // reload window if suceeded
      window.location.reload();
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while more adding subjects");
      }
    }
  };

  return (
    <>
      <Button
        variant="text"
        size="large"
        onClick={() => setAddSubjectsDialog(true)}
        startIcon={<AddIcon sx={{ color: palette.primary[500] }} />}
        sx={{ color: palette.primary[500] }}
      >
        Subjects
      </Button>
      <Dialog
        open={addSubjectsDialog}
        setOpen={setAddSubjectsDialog}
        onClose={() => setAddSubjectsDialog(false)}
        fullWidth
      >
        <CustomDialogTitle onClose={() => setAddSubjectsDialog(false)}>
          Add Subjects
        </CustomDialogTitle>
        <CustomDialogContent>
          <FormControl fullWidth>
            <CustomInputLabel label="Enter number of subjects" />
            <CustomTextField
              fullWidth
              error={error ? true : false}
              helperText={error}
              variant="filled"
              placeholder="up to 500"
              type="number"
              InputProps={{ disableUnderline: true }}
              value={addSubjectsCount}
              onChange={(e) => setAddSubjectsCount(e.target.value)}
              required
            />
          </FormControl>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            disabled={loading}
            type="submit"
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddSubject}
          >
            {loading ? "Adding Subjects" : "Add Subjects"}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
}
