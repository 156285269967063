import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, setNotify } from "../../../redux/utils/utilsSlice";

import {
  Container,
  Typography,
  Stack,
  FormControl,
  Grid,
  Button,
} from "@mui/material";

import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";

import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import {
  getAuthorization,
  getUserCredentials,
  saveUserCredentials,
} from "../../../utils/helpers";
import ChangePassword from "./ChangePassword";

export default function Profile() {
  const auth = useSelector((state) => state.auth);
  const [user] = useState(auth.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    phone: {
      edit: false,
      value: auth.user.phone || "",
    },
    org: {
      edit: false,
      value: auth.user.org || "",
    },
  });

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("profile"));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      // make API request
      const response = await axios({
        method: "PATCH",
        url: `${DOMAIN}/auth/user/`,
        data: {
          phone: formData.phone.value,
          org: formData.org.value,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully updated profile",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      let existing_data = getUserCredentials();

      existing_data["access_token"] = existing_data.accessToken;
      existing_data["refresh_token"] = existing_data.refreshToken;
      existing_data["user"] = response.data;

      saveUserCredentials(existing_data);

      // reload window if suceeded
      window.location.reload();
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while updating profile");
      }
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "48px",
        height: "100vh",
      }}
    >
      <Typography
        data-cy="page__title"
        style={{
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "38px",
          letterSpacing: "0em",
          textAlign: "left",
        }}
      >
        Profile
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            paddingTop: "90px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Stack my={2} direction="column" spacing={4}>
            <Grid container>
              <Grid item xs={6} pr={1}>
                <FormControl fullWidth>
                  <CustomInputLabel label="Enter Your First Name" />
                  <CustomTextField
                    data-cy="input__firstName"
                    disabled
                    fullWidth
                    variant="filled"
                    placeholder="First Name"
                    type="text"
                    value={user["first_name"]}
                    InputProps={{ disableUnderline: true }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} pl={1}>
                <FormControl fullWidth>
                  <CustomInputLabel label="Enter Your Last Name" />
                  <CustomTextField
                    data-cy="input__lastName"
                    disabled
                    fullWidth
                    variant="filled"
                    placeholder="Last Name"
                    type="text"
                    value={user["last_name"]}
                    InputProps={{ disableUnderline: true }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl>
              <CustomInputLabel label="Enter Your Email ID" />
              <CustomTextField
                data-cy="input__email"
                disabled
                fullWidth
                variant="filled"
                placeholder="Email ID"
                type="email"
                value={user["email"]}
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <FormControl>
              <CustomInputLabel
                label="Enter Your Phone No. (Optional)"
                testId="icon__phone"
                editAction={() => {
                  setFormData({
                    ...formData,
                    phone: {
                      edit: true,
                      value: formData.phone.value,
                    },
                  });
                }}
              />
              <CustomTextField
                data-cy="input__phone"
                disabled={!formData.phone.edit}
                fullWidth
                variant="filled"
                value={formData.phone.value}
                placeholder="Phone No."
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phone: {
                      edit: true,
                      value: e.target.value,
                    },
                  });
                }}
                type="tel"
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <FormControl>
              <CustomInputLabel
                label="Enter Name of Your Organisation"
                testId="icon__org"
                editAction={() => {
                  setFormData({
                    ...formData,
                    org: {
                      edit: true,
                      value: formData.org.value,
                    },
                  });
                }}
              />
              <CustomTextField
                data-cy="input__org"
                disabled={!formData.org.edit}
                fullWidth
                variant="filled"
                value={formData.org.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    org: {
                      edit: true,
                      value: e.target.value,
                    },
                  });
                }}
                placeholder="Name of Your Organisation"
                type="text"
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <FormControl>
              <ChangePassword />
              <CustomTextField
                data-cy="input__password"
                disabled
                fullWidth
                helperText={error}
                variant="filled"
                placeholder="Password"
                value=".........."
                type="password"
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
            <Button
              data-cy="button__save"
              disabled={(!formData.phone.edit && !formData.org.edit) || loading}
              disableElevation
              type="submit"
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? "Saving" : "Save"}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
