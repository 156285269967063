import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

export default function CustomDialog({
  open,
  setOpen,
  title,
  content,
  action,
  actionText,
  sx,
}) {
  const { palette } = useTheme();

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle p="32px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="c18px600"
            style={{
              color: palette.neutral[900],
            }}
          >
            {title}
          </Typography>
          <IconButton
            data-cy="icon__close"
            aria-label="close"
            size="small"
            onClick={() => setOpen(false)}
          >
            <Close fontSize="medium" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={sx}>{content}</DialogContent>
      {action && (
        <DialogActions>
          <Button onClick={action}>{actionText}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export function CustomDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const { palette } = useTheme();
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 3,
        fontWeight: 600,
        fontSize: "18px",
        color: palette.neutral[900],
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function CustomDialogActions(props) {
  const { children, ...other } = props;

  return (
    <DialogActions sx={{ paddingY: "16px", paddingRight: "24px" }} {...other}>
      {children}
    </DialogActions>
  );
}

export function CustomDialogContent(props) {
  const { children, ...other } = props;

  return (
    <DialogContent
      dividers
      sx={{ paddingTop: "32px", paddingBottom: "8px" }}
      {...other}
    >
      {children}
    </DialogContent>
  );
}
