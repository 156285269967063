import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./hoc/ProtectedRoute/Index";
import RedirectIfAuth from "./hoc/RedirectIfAuth/Index";

import urls from "../src/utils/urls.json";

// components
import Layout from "./containers/Layout/Index";

// pages
import Trials from "./containers/Pages/Trials";
import Trial from "./containers/Pages/Trial";
import Profile from "./containers/Pages/Profile";
import Login from "./containers/Auth/Login";
import Signup from "./containers/Auth/Signup";
import VerificationEmailSent from "./containers/Auth/VerificationEmailSent";
import EmailVerification from "./containers/Auth/EmailVerification";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";
import Contact from "./containers/Pages/Contact";
import ActivateSession from "./containers/Pages/ActivateSession";
import SessionActivated from "./containers/Pages/SessionActivated";
import AllSurvey from "./containers/Pages/Surveys/AllSurvey";
import AddSurvey from "./containers/Pages/Surveys/AddSurvey";
import Questions from "./containers/Pages/Surveys/Questions";
import PreviewQuestions from "./containers/Pages/Surveys/PreviewQuestions";
import SurveyVersion from "./containers/Pages/SurveyVersion";
import TrialOverview from "./containers/Pages/TrialOverview/TrialOverview";

export default function MyRoutes() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route
            path={urls.activateSession.path}
            element={<ActivateSession />}
          />
          <Route
            path={urls.sessionActivated.path}
            element={<SessionActivated />}
          />
          <Route
            path={urls.home.path}
            element={<ProtectedRoute children={<Trials />} />}
          />
          <Route
            path={urls.trial.path}
            element={<ProtectedRoute children={<Trial />} />}
          />
          <Route
            path={urls.surveys.path}
            element={<ProtectedRoute children={<AllSurvey />} />}
          />
          <Route
            path={urls.surveyVersion.path}
            element={<ProtectedRoute children={<SurveyVersion />} />}
          />
          <Route
            path={urls.newSurvey.path}
            element={<ProtectedRoute children={<AddSurvey />} />}
          />
          <Route
            path={urls.editSurveyVersion.path}
            element={<ProtectedRoute children={<Questions />} />}
          />
          <Route
            path={urls.previewQuestions.path}
            element={<ProtectedRoute children={<PreviewQuestions />} />}
          />
          <Route
            path={urls.trialOverview.path}
            element={<ProtectedRoute children={<TrialOverview />} />}
          />

          <Route
            path={urls.profile.path}
            element={<ProtectedRoute children={<Profile />} />}
          />
          <Route
            path={urls.contact.path}
            element={<ProtectedRoute children={<Contact />} />}
          />
          <Route
            path={urls.signup.path}
            element={<RedirectIfAuth children={<Signup />} />}
          />
          <Route
            path={urls.verificationEmailSent.path}
            element={<VerificationEmailSent />}
          />
          <Route
            path={urls.emailVerification.path}
            element={<EmailVerification />}
          />
          <Route
            path={urls.forgotPassword.path}
            element={<RedirectIfAuth children={<ForgotPassword />} />}
          />
          <Route
            path={urls.resetPassword.path}
            element={<RedirectIfAuth children={<ResetPassword />} />}
          />
          <Route
            path={urls.login.path}
            element={<RedirectIfAuth children={<Login />} />}
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
