import { Stack, Button, Typography, useTheme } from "@mui/material";
import Img from "../../assets/SearchingLaptop.png";

function NoData({ message, actionText, action, height }) {
  const { palette } = useTheme();
  return (
    <Stack
      minHeight={height ? height : "90vh"}
      width="100%"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <img src={Img} alt="illustrations" />
      <Typography
        variant="c20px400"
        style={{
          paddingTop: "30px",
          color: palette.neutral[900],
        }}
      >
        {message || "No data found"}
      </Typography>

      {action ? (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={action}
        >
          {actionText || "Try Again"}
        </Button>
      ) : null}
    </Stack>
  );
}

export default NoData;
