import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControl, Stack, useTheme } from "@mui/material";
import CustomDialog from "../../../components/CustomDialog";
import { CustomTextField } from "../../../components/CustomTextField";
import axios from "axios";
import { CUSTOM_CATEGORY_NAME, DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import {
  setBiomarkerKeys,
  setCategories,
  setSelectedBiomarkerKeys,
} from "../../../redux/biomarkers/biomarkerSlice";
export default function AddBiomarker({
  variant,
  startIcon,
  style,
  biomarkerID,
  id,
}) {
  const [addBiomarkersDialog, setAddBiomarkersDialog] = useState(false);
  const { palette } = useTheme();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const biomarkerCategories = useSelector(
    (state) => state.biomarkers.categories
  );
  const biomarkerKeys = useSelector((state) => state.biomarkers.biomarkerKeys);
  const selectedBiomarkerKeys = useSelector(
    (state) => state.biomarkers.selectedBiomarkerKeys
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check if there is existing custom category
    const customBiomarkerCategories = biomarkerCategories.filter(
      (i) => i.name === CUSTOM_CATEGORY_NAME
    );

    let category_id = null;
    // if yes use that id else create a new custom category (Custom Biomarkers)
    if (customBiomarkerCategories.length) {
      category_id = customBiomarkerCategories[0].id;
    } else {
      // Create a custom category by name of `CUSTOM_CATEGORY_NAME`
      const resp = await axios({
        method: "POST",
        url: `${DOMAIN}/biomarkers/categories/`,
        data: {
          name: CUSTOM_CATEGORY_NAME,
          description: "N/A",
          trial: id,
        },
        params: {
          trial_id: id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      category_id = resp.data.id;
      const newCategory = {
        name: resp.data.name,
        editable: true,
        index: biomarkerCategories[biomarkerCategories.length - 1].index + 1,
        id: resp.data.id,
        biomarkers: [],
      };
      dispatch(setCategories([...biomarkerCategories, newCategory]));
    }

    // use that category to add new biomarker
    try {
      const resp = await axios({
        method: "POST",
        url: `${DOMAIN}/biomarkers/`,
        data: {
          id: biomarkerID,
          name: title,
          description: description || "N/A",
          category: category_id,
        },
        params: {
          id: id,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });
      dispatch(setBiomarkerKeys([...biomarkerKeys, resp.data]));
      dispatch(
        setSelectedBiomarkerKeys([...selectedBiomarkerKeys, resp.data.id])
      );

      setTitle("");
      setDescription("");
      setAddBiomarkersDialog(false);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while adding biomarker");
      }
    }
  };

  return (
    <>
      <Button
        variant={variant}
        style={style}
        onClick={() => setAddBiomarkersDialog(true)}
        startIcon={startIcon}
      >
        Add Biomarker
      </Button>
      <CustomDialog
        open={addBiomarkersDialog}
        setOpen={setAddBiomarkersDialog}
        title="Add Biomarkers"
        content={
          <Stack spacing={2} pt="15px">
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormControl fullWidth>
                <CustomTextField
                  fullWidth
                  error={error ? true : false}
                  helperText={error}
                  variant="filled"
                  placeholder="Enter Biomarker Title"
                  type="text"
                  InputProps={{ disableUnderline: true }}
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <CustomTextField
                  fullWidth
                  error={error ? true : false}
                  helperText={error}
                  variant="filled"
                  placeholder="Enter Description"
                  type="text"
                  InputProps={{ disableUnderline: true }}
                  required
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    position: "sticky",
                    bottom: "0",
                    backgroundColor: palette.neutral[100],
                    padding: "10px 10px",
                  }}
                >
                  <div></div>
                  <div>
                    <Button
                      disabled={loading}
                      type="submit"
                      disableElevation
                      fullWidth
                      variant="outlined"
                      color="primary"
                      sx={{
                        padding: "12px 32px 12px 32px",
                        borderRadius: "6px",
                      }}
                    >
                      {loading ? "Adding Biomarkers" : "Add"}
                    </Button>
                  </div>
                </Box>
              </FormControl>
            </form>
          </Stack>
        }
      />
    </>
  );
}
