import { useState } from "react";
import * as React from "react";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import urls from "../../utils/urls.json";
import { DOMAIN } from "../../utils/config";
import { styled } from "@mui/material/styles";

// Material UI components
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField as TextFieldBase,
  Typography,
} from "@mui/material";

// CAROUSEL
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import carousel1 from "../../assets/carousel1.svg";
import carousel2 from "../../assets/carousel2.svg";
import carousel3 from "../../assets/carousel3.svg";
import { isValidEmail } from "../../utils/emailValid";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    heading: "START TRIALS EASILY",
    description: null,
    imgPath: carousel1,
  },
  {
    heading: "ACCESS ALL YOUR TRIALS AT ONE PLACE",
    description: null,
    imgPath: carousel2,
  },
  {
    heading: "DOWNLOAD SOPS AND SUBJECT INFO TABLE AT EASE",
    description: null,
    imgPath: carousel3,
  },
];

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

export default function Signup() {
  const { palette } = useTheme();
  // local state variables
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [org, setOrg] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState(null);
  const [errorLastName, setErrorLastName] = useState(null);
  const [errorOrg, setErrorOrg] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword1, setErrorPassword1] = useState(null);
  const [errorPassword2, setErrorPassword2] = useState(null);
  const [error, setError] = useState(null);
  // CAROUSEL
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // react-router-dom navigator
  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    // handle form submit
    e.preventDefault();

    // remove errors from state, if any
    setError(null);
    setErrorFirstName(null);
    setErrorLastName(null);
    setErrorOrg(null);
    setErrorEmail(null);
    setErrorPassword1(null);
    setErrorPassword2(null);

    // set loading to true
    setLoading(true);

    if (!firstName) {
      setErrorFirstName("First name is required");
      setLoading(false);
      return;
    }

    if (!lastName) {
      setErrorLastName("Last name is required");
      setLoading(false);
      return;
    }

    if (!email) {
      setErrorEmail("Email is required");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setErrorEmail("Email is not valid");
      setLoading(false);
      return;
    }

    if (!org) {
      setErrorOrg("Organisation is required");
      setLoading(false);
      return;
    }

    if (!password1) {
      setErrorPassword1("Password is required");
      setLoading(false);
      return;
    }

    if (!password2) {
      setErrorPassword2("Confirm password is required");
      setLoading(false);
      return;
    }

    try {
      // make API request
      await axios({
        url: `${DOMAIN}/auth/registration/`,
        method: "POST",
        data: {
          first_name: firstName,
          last_name: lastName,
          org,
          email,
          password1,
          password2,
        },
      });
      // redirect to home page
      navigator(urls.verificationEmailSent.path);
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);

        // set error
        setErrorFirstName(
          error_object.first_name && error_object.first_name[0]
        );
        setErrorLastName(error_object.last_name && error_object.last_name[0]);
        setErrorOrg(error_object.org && error_object.org[0]);
        setErrorEmail(error_object.email && error_object.email[0]);
        setErrorPassword1(error_object.password1 && error_object.password1[0]);
        setErrorPassword2(error_object.password2 && error_object.password2[0]);
        setError(
          error_object.non_field_errors && error_object.non_field_errors[0]
        );
      } catch {
        // default error message
        setError(["Unable to signup"]);
      }
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    setErrorFirstName(null);
    setErrorLastName(null);
    setErrorOrg(null);
    setErrorEmail(null);
    setErrorPassword1(null);
    setErrorPassword2(null);

    func(value);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          px={2}
          style={{
            height: "100vh",
            backgroundColor: palette.neutral[100],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            py={5}
            style={{
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography
              variant="c32px600"
              style={{
                textAlign: "left",
              }}
              data-cy="page__title"
            >
              Register
            </Typography>
            <Typography
              variant="c20px500"
              style={{
                textAlign: "left",
              }}
              data-cy="page__greeting"
            >
              Welcome to Neurobit HUB!
            </Typography>
            <Stack my={2} direction="column" spacing={2}>
              <Grid container>
                <Grid item xs={6} pr={1}>
                  <FormControl fullWidth>
                    <InputLabel shrink variant="standard">
                      Enter Your First Name
                    </InputLabel>
                    <TextField
                      data-cy="input__firstName"
                      error={errorFirstName ? true : false}
                      helperText={errorFirstName}
                      fullWidth
                      variant="filled"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={(e) =>
                        handleChange(setFirstName, e.target.value)
                      }
                      InputProps={{ disableUnderline: true }}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} pl={1}>
                  <FormControl fullWidth>
                    <InputLabel shrink variant="standard">
                      Enter Your Last Name
                    </InputLabel>
                    <TextField
                      data-cy="input__lastName"
                      error={errorLastName ? true : false}
                      helperText={errorLastName}
                      fullWidth
                      variant="filled"
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) =>
                        handleChange(setLastName, e.target.value)
                      }
                      InputProps={{ disableUnderline: true }}
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl>
                <InputLabel shrink variant="standard">
                  Enter Your Email ID
                </InputLabel>
                <TextField
                  data-cy="input__email"
                  error={errorEmail ? true : false}
                  helperText={errorEmail}
                  fullWidth
                  variant="filled"
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => handleChange(setEmail, e.target.value)}
                  type="email"
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              <FormControl>
                <InputLabel shrink variant="standard">
                  Enter Name of Your Organisation
                </InputLabel>
                <TextField
                  data-cy="input__organisation"
                  error={errorOrg ? true : false}
                  helperText={errorOrg}
                  fullWidth
                  variant="filled"
                  placeholder="Name of Your Organisation"
                  type="text"
                  value={org}
                  onChange={(e) => handleChange(setOrg, e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              <FormControl>
                <InputLabel shrink variant="standard">
                  Create Password
                </InputLabel>
                <TextField
                  data-cy="input__password"
                  error={errorPassword1 ? true : false}
                  helperText={errorPassword1}
                  fullWidth
                  variant="filled"
                  placeholder="Password"
                  type="password"
                  value={password1}
                  onChange={(e) => handleChange(setPassword1, e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              <FormControl>
                <InputLabel shrink variant="standard">
                  Confirm Password
                </InputLabel>
                <TextField
                  data-cy="input__confirmPassword"
                  error={errorPassword2 ? true : false}
                  helperText={errorPassword2}
                  fullWidth
                  variant="filled"
                  placeholder="Password"
                  type="password"
                  value={password2}
                  onChange={(e) => handleChange(setPassword2, e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              {
                // display error messages from the state, if any
                error ? (
                  <p
                    style={{
                      color: palette.error.main,
                    }}
                  >
                    {error}
                  </p>
                ) : null
              }
              <Button
                data-cy="button__register"
                disableElevation
                disabled={loading}
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={handleSubmit}
              >
                Register
              </Button>
            </Stack>
            <Typography
              style={{
                fontSize: "16px",
                textAlign: "center",
                borderBottom: "1px solid #CDCDCD",
                lineHeight: "0.1em",
                margin: "10px 0 20px",
              }}
            >
              <span
                style={{
                  background: "#fff",
                  padding: "0 10px",
                  color: palette.neutral[900],
                }}
              >
                OR
              </span>
            </Typography>
            <Button
              component={Link}
              to={urls.login.path}
              disableElevation
              size="large"
              color="primary"
              variant="outlined"
              style={{ textTransform: "none" }}
            >
              Log In
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        style={{
          backgroundColor: palette.primary[700],
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ backgroundColor: "transparent" }}
            />
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                variant="c16px600"
                style={{
                  color: palette.neutral[100],
                  textAlign: "left",
                }}
              >
                {images[activeStep].heading}{" "}
              </Typography>
              <Typography
                variant="c14px500"
                style={{
                  color: palette.neutral[100],
                  textAlign: "left",
                }}
              >
                {images[activeStep].description}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
