import { useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/utils/utilsSlice";

import urls from "../../utils/urls.json";
import { useNavigate } from "react-router-dom";
import Success from "../../components/Blockers/Success";

export default function VerificationEmailSent() {
  // redux
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setActiveTab(urls.verificationEmailSent.name));
  }, [dispatch]);

  return (
    <Success
      text="Verification Email Sent, Please Verify First."
      handleContinue={() => navigate(urls.login.path)}
    />
  );
}
