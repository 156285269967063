import { useEffect, useState } from "react";

// redux
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/utils/utilsSlice";

import urls from "../../utils/urls.json";
import Loading from "../../components/Blockers/Loading";
import { useNavigate, useParams } from "react-router-dom";
import Success from "../../components/Blockers/Success";

import { DOMAIN } from "../../utils/config";

import axios from "axios";

export default function EmailVerification() {
  const [verified, setVerified] = useState(false);

  // redux
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // react-router-dom
  const { key } = useParams();

  useEffect(() => {
    axios({
      method: "POST",
      url: `${DOMAIN}/auth/registration/verify-email/`,
      data: {
        key,
      },
    })
      .then((res) => {
        setVerified(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [key]);

  useEffect(() => {
    dispatch(setActiveTab(urls.emailVerification.name));
  }, [dispatch]);

  return verified ? (
    <Success
      text="Email Verified"
      handleContinue={() => navigate(urls.home.path)}
    />
  ) : (
    <Loading loading={true} />
  );
}
