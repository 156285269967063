import { CheckCircle } from "@mui/icons-material";
import { Button, Stack, Typography, useTheme } from "@mui/material";

export default function Success({ text, handleContinue }) {
  const { palette } = useTheme();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "60vh",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <CheckCircle sx={{ color: palette.success.main }} fontSize="large" />
        <Typography align="center">{text || "Success"}</Typography>
        {handleContinue && (
          <Button variant="outlined" color="inherit" onClick={handleContinue}>
            Continue
          </Button>
        )}
      </Stack>
    </div>
  );
}
