import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

import { Typography, Button, Stack, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

import moment from "moment";

import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import { FileDownloadOutlined } from "@mui/icons-material";

const ReportButton = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: "14px",
  padding: "8px 12px",
  border: "1px solid #F7F7F7",
  lineHeight: "16.8px",
  borderRadius: "4px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#f9f9f9",
    borderColor: "#f9f9f9",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#f9f9f9",
    borderColor: "#f9f9f9",
  },
  "&:focus": {},
}));

function Test(props) {
  const [loadingUserReport, setLoadingUserReport] = useState(false);
  const [loadingClinicianReport, setLoadingClinicianReport] = useState(false);
  const [loadingRawJSON, setLoadingRawJSON] = useState(false);
  const [loadingProcessedJSON, setLoadingProcessedJSON] = useState(false);
  const { palette } = useTheme();

  // redux
  const dispatch = useDispatch();

  const download = (reportType) => {
    // notify
    dispatch(
      setNotify({
        open: true,
        action: "Fetching Report",
        severity: "info",
        autoHideDuration: 3000,
        vertical: "bottom",
        horizontal: "right",
      })
    );

    let loader = null;
    let report_format = reportType;
    switch (reportType) {
      case "userReport":
        loader = setLoadingUserReport;
        break;
      case "clinicianReport":
        loader = setLoadingClinicianReport;
        break;
      case "rawJSON":
        loader = setLoadingRawJSON;
        report_format = "raw.json";
        break;
      case "processedJSON":
        loader = setLoadingProcessedJSON;
        report_format = "final.json";
        break;
      default:
        break;
    }

    loader(true);

    axios({
      method: "GET",
      url: `${DOMAIN}/trials/report/download/`,
      params: {
        id: props.id,
        report_format,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        loader(false);

        const file_url = res.data.file_url;

        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Successfully downloaded report",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );

        window.open(file_url, "_blank");
      })
      .catch((err) => {
        loader(false);

        dispatch(
          setNotify({
            open: true,
            action: "The Report is not ready yet, please try after some time.",
            severity: "error",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        console.log("ERROR:", err);
      });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ paddingX: "16px" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: "space-between", ll: "center" }}
        gap={{ xs: 2.4, ll: 3.2, xl: 6, xxl: 10, dt: 15 }}
      >
        <Typography
          variant="c16px400"
          style={{
            textAlign: "left",
            color: palette.neutral[900],
          }}
        >
          Test #{props.test_id}
        </Typography>
        <Typography
          variant="c14px400"
          style={{
            textAlign: "left",
            color: palette.neutral[900],
          }}
        >
          {moment(props.created_on).format("DD-MMM-YY")}
        </Typography>
        <Typography
          variant="c14px400"
          style={{
            textAlign: "left",
            color: palette.neutral[900],
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          sx={{ width: { xs: "120px", ll: "136px", xxl: "auto", dt: "auto" } }}
        >
          Session ID: {props.session.session_key}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: "space-between", ll: "center" }}
        gap={{ xs: 2.4, ll: 3, xl: 4, dt: 4 }}
      >
        <ReportButton
          disabled={loadingUserReport}
          startIcon={<FileDownloadOutlined color="primary" />}
          sx={{
            paddingX: { xs: "8px", ll: "12px", xxl: "24px", dt: "32px" },
          }}
          onClick={() => download("userReport")}
        >
          User Report
        </ReportButton>
        <ReportButton
          disabled={loadingClinicianReport}
          startIcon={<FileDownloadOutlined color="primary" />}
          sx={{
            paddingX: { xs: "8px", ll: "12px", xxl: "24px", dt: "32px" },
          }}
          onClick={() => download("clinicianReport")}
        >
          Clinician Report
        </ReportButton>
        <ReportButton
          disabled={loadingRawJSON}
          startIcon={
            <FileDownloadOutlined color="primary" sx={{ marginRight: "0" }} />
          }
          sx={{
            paddingX: { xs: "8px", ll: "12px", xxl: "24px", dt: "32px" },
          }}
          onClick={() => download("rawJSON")}
        >
          Raw JSON
        </ReportButton>
        <ReportButton
          disabled={loadingProcessedJSON}
          startIcon={<FileDownloadOutlined color="primary" />}
          sx={{
            paddingX: { xs: "8px", ll: "12px", xxl: "24px", dt: "32px" },
          }}
          onClick={() => download("processedJSON")}
        >
          Processed JSON
        </ReportButton>
      </Stack>
    </Stack>
  );
}

export default Test;
