import { createSlice } from "@reduxjs/toolkit";

export const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    loading: false,
    notify: {
      open: false,
      action: null,
      severity: null,
      autoHideDuration: 5000,
      vertical: "bottom",
      horizontal: "right",
    },
    dialog: {
      open: false,
      title: null,
      content: null,
      action: {},
    },
    activeTab: null, // trials, profile, contact
    saver: {
      open: false,
      title: null,
      action: {},
    }
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNotify: (state, action) => {
      state.notify = action.payload;
    },
    setDialog: (state, action) => {
      state.dialog = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setSaver: (state, action) => {
      state.saver = action.payload;
    },
  },
});

export const {
  setLoading,
  setActiveTab,
  setNotify,
  setDialog,
  setSaver
} = utilsSlice.actions;

export default utilsSlice.reducer;
