import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField as TextFieldBase,
  Typography,
  useTheme,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// CAROUSEL
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import carousel1 from "../../assets/carousel1.svg";
import carousel2 from "../../assets/carousel2.svg";
import carousel3 from "../../assets/carousel3.svg";

import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../redux/utils/utilsSlice";

import axios from "axios";
import { DOMAIN } from "../../utils/config";
import { isValidEmail } from "../../utils/emailValid";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    heading: "START TRIALS EASILY",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
    imgPath: carousel1,
  },
  {
    heading: "ACCESS ALL YOUR TRIALS AT ONE PLACE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
    imgPath: carousel2,
  },
  {
    heading: "DOWNLOAD SOPS AND SUBJECT INFO TABLE AT EASE",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",

    imgPath: carousel3,
  },
];

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2),
  },
  "& .MuiInputBase-input": {
    borderBottom: "0px",
    fontSize: 16,
    padding: "10px 12px",
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { palette } = useTheme();

  // redux
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    if (!email) {
      setError("Email is required");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setError("Email is not valid");
      setLoading(false);
      return;
    }

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/auth/password/reset/`,
        data: {
          email,
        },
      });

      // set loading to false
      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Email Successfully Sent! (if email-id is valid)",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    } catch (err) {
      // set loading to false
      setLoading(false);

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Something Went Wrong!",
          severity: "error",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );
    }
  };

  const handleChange = (func, value) => {
    // handle change of input values

    // remove errors from state, if anny
    setError(null);
    func(value);
  };

  // CAROUSEL
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box
          px={2}
          style={{
            height: "100vh",
            backgroundColor: palette.neutral[100],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={3}
            py={5}
            style={{
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <Typography
              variant="c32px600"
              data-cy="page__title"
              style={{
                color: palette.neutral[900],
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              variant="c16px500"
              style={{ color: palette.neutral[900] }}
            >
              Enter your email ID, a link to change the password will be sent.
            </Typography>
            <Stack my={2} direction="column" spacing={2}>
              <FormControl>
                <TextField
                  data-cy="input__email"
                  error={error ? true : false}
                  helperText={error}
                  fullWidth
                  variant="filled"
                  placeholder="Email ID"
                  type="email"
                  value={email}
                  onChange={(e) => handleChange(setEmail, e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  required
                />
              </FormControl>
              <Button
                data-cy="button__sendLink"
                type="submit"
                disableElevation
                disabled={loading}
                size="large"
                color="primary"
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={handleSubmit}
              >
                Send Link
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        style={{
          backgroundColor: palette.primary[700],
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ backgroundColor: "transparent" }}
            />
            <Stack
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                variant="c16px600"
                style={{
                  color: palette.neutral[100],
                }}
              >
                {images[activeStep].heading}{" "}
              </Typography>
              <Typography
                style={{
                  color: palette.neutral[100],
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                {images[activeStep].description}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
