import { useEffect, useState, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useParams, Link } from "react-router-dom";
import { Autorenew, Check, ExpandMore, Search } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  Stack,
  Typography,
  TextField as TextFieldBase,
  FormControl,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Paper,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  useTheme,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";

import { styled } from "@mui/material/styles";

import Loading from "../../../components/Blockers/Loading";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";
import AddSubjects from "./AddSubjects";
import NoData from "../../../components/Blockers/NoData";
import { setNotify } from "../../../redux/utils/utilsSlice";
import CustomPagination from "../../../components/CustomPagination";
// import AddSession from "./AddSession";
import Test from "./Test";
import DownloadOptions from "./DownloadOptions";
import GenerateTable from "./GenerateTable";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DataGridTable from "./DataGridTable";

import SendNotification from "./SendNotification";
import TrialMoreOption from "./TrialMoreOption";

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "0px",
    padding: "10px",
    fontSize: 16,
  },
  overflow: "hidden",
  borderRadius: "4px",
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
///////// Tab component ///////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function Trial(props) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { palette } = useTheme();

  const [loading, setLoading] = useState(true);
  const [loadingSubjects, setLoadingSubjects] = useState(true);

  const [activeOnly, setActiveOnly] = useState(false);
  const [trial, setTrial] = useState({});
  const [subjects, setSubjects] = useState({});
  const [createdHistory, setCreatedHistory] = useState([]);

  // table generation
  const [tableGenerated, setTableGenerated] = useState(false);

  const [search, setSearch] = useState("");

  // react-router-dom navigator
  const { id } = useParams();

  // pagination data
  const limit = 10;
  const [page, setPage] = useState(1);

  const saver = useSelector((state) => state.utils.saver);

  const userId = JSON.parse(localStorage.getItem("user")).id;

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    axios({
      method: "GET",
      url: `${DOMAIN}/trials/`,
      params: {
        id,
        deep: false,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const data = res.data.payload[0];
        setTrial(data);

        if (
          data.metadata &&
          data.metadata.history &&
          data.metadata.history.subject &&
          data.metadata.history.subject.created
        ) {
          setCreatedHistory(data.metadata.history.subject.created);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR:", err);
      });
  }, [id]);

  const fetchSubjects = useCallback(
    (page) => {
      setSubjects({});
      setLoadingSubjects(true);

      axios({
        method: "GET",
        url: `${DOMAIN}/trials/subjects/`,
        params: {
          trial: id,
          page,
          limit,
          deep: true,
          status: activeOnly ? "subject.registered" : null,
          search,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      })
        .then((res) => {
          const data = res.data;
          let subjects = [];
          res.data.payload.forEach((subject) => {
            let tests = [];
            subject.sessions.forEach((session) => {
              session.tests.forEach((test) => {
                tests.push({
                  ...test,
                  session: session,
                });
              });
            });
            subjects.push({
              ...subject,
              sessions: subject.sessions.map((s) => s.id),
              tests,
            });
          });

          setSubjects({
            ...data,
            payload: subjects,
          });
          setLoadingSubjects(false);
        })
        .catch((err) => {
          console.log("ERROR:", err);
        });
    },
    [id, search, activeOnly]
  );

  useEffect(() => {
    if (page === 1) {
      fetchSubjects(page);
    }
  }, [id, fetchSubjects, page]);

  const breadcrumbs = [
    <Link key="1" to="/">
      <Typography variant="c16px500" sx={{ color: palette.neutral[900] }}>
        Trials
      </Typography>
    </Link>,
    <Typography variant="c18px600" key="2" sx={{ color: palette.neutral[900] }}>
      {trial.name}
    </Typography>,
  ];

  const TestList = (props) => (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: palette.neutral[100],
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          padding: "8px 32px",
          borderBottom: "1px solid #F7F7F7",
        }}
      >
        <Typography
          variant="c16px600"
          style={{
            textAlign: "left",
          }}
        >
          Tests ({props.tests.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: "8px 16px",
        }}
      >
        <Stack spacing={3}>
          {props.tests && props.tests.length ? (
            props.tests.map((item, index) => <Test key={index} {...item} />)
          ) : (
            <Typography align="center">No test submitted yet</Typography>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );

  const Subject = (props) => (
    <Grid item xs={12}>
      <Paper elevation={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: "16px 32px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={20}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Typography
                variant="c16px600"
                style={{
                  textAlign: "left",
                  color: palette.primary[700],
                }}
              >
                Subject ID: {props.uid}
              </Typography>
              <Typography
                variant="c16px500"
                style={{
                  textAlign: "left",
                  color: palette.primary[700],
                }}
              >
                Secret Key: {props.secret_id}
              </Typography>
            </Stack>
            {props.sessions && props.sessions.length ? null : (
              <Typography
                variant="c14px400"
                style={{
                  textAlign: "left",
                  color: palette.neutral[700],
                }}
              >
                This subject has yet to start a session
              </Typography>
            )}
          </Stack>
          {/* Send Notification */}
          <SendNotification
            subjectId={props.id}
            enabled={props.sessions && props.sessions.length}
          />
          {/* <AddSession subject={props} /> */}
        </Stack>
        <Stack spacing={2}>
          <TestList tests={props.tests} />
        </Stack>
      </Paper>
    </Grid>
  );

  const handleDownloadPDF = ({ data, _case }) => {
    // notify
    dispatch(
      setNotify({
        open: true,
        action: "Fetching PDF",
        severity: "info",
        autoHideDuration: 3000,
        vertical: "bottom",
        horizontal: "right",
      })
    );

    axios({
      method: "GET",
      url: `${DOMAIN}/trials/pdf/download/`,
      params: {
        id,
        created_at_ts: data.created_at_ts,
        start_uid: data.start_uid,
        end_uid: data.end_uid,
        case: _case,
      },
      headers: {
        Authorization: getAuthorization(),
      },
    })
      .then((res) => {
        const file_url = res.data.file_url;

        // notify
        dispatch(
          setNotify({
            open: true,
            action: "Downloading PDF",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );

        window.open(file_url, "_blank");
      })
      .catch((err) => {
        dispatch(
          setNotify({
            open: true,
            action: "The PDF is not ready yet, please try after some time.",
            severity: "error",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        console.log("ERROR:", err);
      });
  };

  const getIcon = (iconName) => {
    const iconStyle = {
      fontSize: "16px",
      marginRight: "5px",
      marginBottom: "-3.5px",
      color: "#999",
    };
    switch (iconName) {
      case "autoRenew":
        return <Autorenew style={iconStyle} />;
      case "Check":
        return <Check style={iconStyle} />;
      default:
        return null;
    }
  };

  return (
    <Loading loading={loading}>
      <Grid
        container
        style={{
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
          }}
        >
          <Box>
            {/* START: Top Nav */}
            <Stack
              direction="column"
              // component={Paper}
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: palette.neutral[100],
                zIndex: 101,
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.07)",
              }}
            >
              {/* START: First Row */}
              <Stack
                px={{ xs: "32px", ll: "48px" }}
                paddingTop={{ xs: "32px", ll: "48px" }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Breadcrumbs aria-label="breadcrumb">
                    {breadcrumbs}
                  </Breadcrumbs>
                  {saver.open && (
                    <>
                      <Typography
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          color: "#999",
                        }}
                      >
                        {getIcon(saver.action && saver.action.icon)}
                        {saver.title}
                      </Typography>
                    </>
                  )}
                </Stack>
                {/* Surveys, Trial Overview and Share Trial */}
                <TrialMoreOption
                  trialId={id}
                  isOwner={trial.partner === userId}
                />
              </Stack>
              {/* END: First Row */}
              {/* START: Second Row */}
              <Box px={{ xs: "32px", ll: "48px" }}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    label="Subjects Info"
                    sx={{
                      fontSize: "16px",
                      paddingX: "0",
                    }}
                  />
                  <Tab
                    label="Insights Table"
                    sx={{
                      fontSize: "16px",
                      paddingX: "0",
                      ml: "24px",
                    }}
                  />
                </Tabs>
              </Box>
              {/* START: Third Row */}
              <TabPanel value={value} index={0}>
                <Stack
                  px={{ xs: "32px", ll: "48px" }}
                  py="24px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    spacing={5}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControl>
                      <TextField
                        fullWidth
                        variant="filled"
                        placeholder="Search by subject ID"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setPage(1);
                          setSearch(e.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={activeOnly}
                            color="primary"
                            style={{ marginRight: "4px" }}
                            onChange={() => {
                              setActiveOnly(!activeOnly);
                              setPage(1);
                              fetchSubjects(1);
                            }}
                          />
                        }
                        label="Show subjects with active sessions"
                      />
                    </FormGroup>
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <AddSubjects id={id} />
                    <DownloadOptions
                      title="SOPs"
                      options={createdHistory}
                      onDownload={(item) =>
                        handleDownloadPDF({ data: item, _case: "sop" })
                      }
                      startIcon={
                        <FileDownloadOutlinedIcon
                          sx={{ color: palette.primary[500] }}
                        />
                      }
                    />
                    <DownloadOptions
                      title="Subjects Table"
                      options={createdHistory}
                      onDownload={(item) =>
                        handleDownloadPDF({
                          data: item,
                          _case: "subjects-table",
                        })
                      }
                      startIcon={
                        <FileDownloadOutlinedIcon
                          sx={{ color: palette.primary[500] }}
                        />
                      }
                    />
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Stack
                  px={{ xs: "32px", ll: "48px" }}
                  py="12px"
                  paddingBottom="24px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    spacing={5}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GenerateTable setTableGenerated={setTableGenerated} />
                  </Stack>
                </Stack>
              </TabPanel>

              {/* END: Third Row */}
            </Stack>
            <TabPanel value={value} index={0}>
              {/* START: Body */}
              <Grid
                container
                px={{ xs: "32px", ll: "48px" }}
                py="24px"
                style={{
                  zIndex: 100,
                }}
                spacing={2}
              >
                {loadingSubjects ? (
                  <Loading loading={loadingSubjects} height="70vh" />
                ) : subjects && subjects.payload && subjects.payload.length ? (
                  subjects.payload.map((item, index) => (
                    <Subject key={index} {...item} />
                  ))
                ) : (
                  <NoData
                    message="Looks like you haven't added any subjects yet"
                    height="70vh"
                  />
                )}
              </Grid>
              {/* END: Body */}
              {/* START: CustomPagination */}
              {(page > 1 ||
                (subjects.payload && subjects.payload.length >= limit)) && (
                <CustomPagination
                  disabled={loadingSubjects}
                  last_page_no={subjects.last_page_no}
                  limit={subjects.payload && subjects.payload.length}
                  page={page}
                  handlePaginationChange={(_, value) => {
                    fetchSubjects(value);
                    setPage(value);
                  }}
                />
              )}
              {/* END: CustomPagination */}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack sx={{ padding: "20px 24px" }}>
                {tableGenerated && <DataGridTable />}
              </Stack>
            </TabPanel>
          </Box>

          {/* END: Second Row */}

          {/* END: Top Nav */}
        </Grid>
      </Grid>
    </Loading>
  );
}

export default Trial;
