import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  Dialog,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import { Close, Sync } from "@mui/icons-material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorization } from "../../../utils/helpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SUBMIT_FILTER_STROKE_TIME = 500;

const DEFAULT_COLUMNS = [
  {
    field: "subject",
    isDefault: true,
    width: 150,
    headerName: "Subject",
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: "date",
    isDefault: true,
    width: 150,
    headerName: "Date",
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: "time",
    isDefault: true,
    width: 150,
    headerName: "Time",
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
  {
    field: "test",
    isDefault: true,
    width: 150,
    headerName: "Test",
    filterOperators: [
      {
        label: "Between",
        value: "between",
        getApplyFilterFn: (filterItem) => {
          if (
            !Array.isArray(filterItem.value) ||
            filterItem.value.length !== 2
          ) {
            return null;
          }
          if (filterItem.value[0] == null || filterItem.value[1] == null) {
            return null;
          }

          return ({ value }) => {
            return (
              value !== null &&
              filterItem.value[0] <= value &&
              value <= filterItem.value[1]
            );
          };
        },
        InputComponent: InputNumberInterval,
      },
    ],
    headerClassName: "data-grid-head",
    description:
      "The identification used by the person with access to the online service.",
  },
];

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function InputNumberInterval(props) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef();
  const [filterValueState, setFilterValueState] = React.useState(
    item.value ?? ""
  );

  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleUpperFilterChange = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={Number(filterValueState[0])}
        onChange={handleLowerFilterChange}
        type="number"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="To"
        label="To"
        variant="standard"
        value={Number(filterValueState[1])}
        onChange={handleUpperFilterChange}
        type="number"
        InputProps={applying ? { endAdornment: <Sync /> } : {}}
      />
    </Box>
  );
}

InputNumberInterval.propTypes = {
  applyValue: PropTypes.func.isRequired,
  focusElementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  item: PropTypes.shape({
    /**
     * The column from which we want to filter the rows.
     */
    columnField: PropTypes.string.isRequired,
    /**
     * Must be unique.
     * Only useful when the model contains several items.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The name of the operator we want to apply.
     * Will become required on `@mui/x-data-grid@6.X`.
     */
    operatorValue: PropTypes.string,
    /**
     * The filtering value.
     * The operator filtering function will decide for each row if the row values is correct compared to this value.
     */
    value: PropTypes.any,
  }).isRequired,
};

export default function DataGridTable(props) {
  const { palette } = useTheme();
  const { surveyVersionId } = useParams();

  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setColumns([]);
        const response = await axios({
          url: `${DOMAIN}/surveys/versions/surveyresponses`,
          method: "GET",
          params: {
            id: surveyVersionId,
          },
          headers: {
            Authorization: getAuthorization(),
          },
        });
        // TODO: use order_id instead of id

        let tempQuestionType = {};

        const newArr = response.data.questions
          .sort(function (a, b) {
            return a.id - b.id;
          })
          .flatMap(
            (
              {
                id,
                survey_version,
                question_type,
                title: qTitle,
                description: qDescription,
                qMetadata,
                options,
              },
              questionIndex
            ) => {
              let data = [];
              const isScq = question_type === "scq";
              const isText = question_type === "text";

              tempQuestionType = {
                ...tempQuestionType,
                [id]: question_type,
              };

              if (!options.length || isScq) {
                data = [
                  {
                    field: `${id}`,
                    width: 150,
                    headerName: `Q${questionIndex + 1}`,
                    headerClassName: "data-grid-head",
                    valueGetter: (params) => {
                      if (isScq) {
                        if (params.row[`${id}`]) {
                          return options.filter(
                            (i) => i.id === params.row[`${id}`]
                          )[0].title;
                        }
                        return "-";
                      }
                      if (params.row[`${id}`]) return params.row[`${id}`];
                      return "-";
                    },
                    question: {
                      id,
                      uid: questionIndex + 1,
                      title: qTitle,
                      description: qDescription,
                      question_type,
                      options,
                    },
                    sortable: false,
                    editable: isText,
                  },
                ];
              }
              if (question_type === "mcq") {
                data = [
                  ...data,
                  ...options
                    .sort(function (a, b) {
                      return a.id - b.id;
                    })
                    .map(
                      (
                        { id, question, title, description, metadata },
                        optionIndex
                      ) => {
                        const field = `${question}_${id}`;
                        return {
                          isOption: true,
                          field: field,
                          width: 150,
                          headerName: `Q${questionIndex + 1} (Option${
                            optionIndex + 1
                          })`,
                          sortable: false,
                          headerClassName: "data-grid-head",
                          valueGetter: (params) => {
                            if (params.row[field]) return title;
                            return "-";
                          },
                          question: {
                            id: question,
                            uid: questionIndex + 1,
                            title: qTitle,
                            description: qDescription,
                            options,
                          },
                        };
                      }
                    ),
                ];
              }
              return data;
            }
          );
        setColumns([...DEFAULT_COLUMNS, ...newArr]);

        let resArr = [];
        response.data.responses.forEach((subject, test) => {
          subject.responses.forEach((response) => {
            let dateTime = new Date(response.created_on);

            // Extract date parts
            let year = dateTime.getFullYear();
            let month = ("0" + (dateTime.getMonth() + 1)).slice(-2); // Zero-padding the month
            let date = ("0" + dateTime.getDate()).slice(-2); // Zero-padding the date
            // Extract time parts
            let hours = ("0" + dateTime.getHours()).slice(-2); // Zero-padding the hours
            let minutes = ("0" + dateTime.getMinutes()).slice(-2); // Zero-padding the minutes
            let seconds = ("0" + dateTime.getSeconds()).slice(-2); // Zero-padding the seconds

            let rowData = {
              id: response.id,
              subject: subject.uid,
              test: response.test_id || "-",
              date: `${year}-${month}-${date}`,
              time: `${hours}:${minutes}:${seconds}`,
            };
            response.answers.forEach((answer) => {
              if (answer.text) {
                rowData = {
                  ...rowData,
                  [`${answer.question}`]: answer.text,
                };
              } else {
                answer.options.forEach((option) => {
                  const questionType = tempQuestionType[answer.question];
                  const isScq = questionType === "scq";
                  rowData = {
                    ...rowData,
                    [isScq
                      ? `${answer.question}`
                      : `${answer.question}_${option}`]: option,
                  };
                });
              }
            });
            resArr = [...resArr, rowData];
          });
        });
        setRows(resArr);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [surveyVersionId]);

  const handleClose = () => {
    setOpen(false);
    setDialogData({});
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Stack
          sx={{ padding: "20px", width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="c16px600">Response Table</Typography>
          <GridToolbarExport />
        </Stack>
      </GridToolbarContainer>
    );
  }
  return (
    <Box
      sx={{
        height: "83vh",
        "& .data-grid-head": {
          cursor: "pointer",
        },
        padding: "24px",
      }}
    >
      <DataGrid
        hideSortIcons={true}
        disableSelectionOnClick
        disableColumnSelectors
        hideFooter
        hideFooterPagination
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
        }}
        rows={rows}
        columns={columns}
        onColumnHeaderClick={(e) => {
          if (!e.colDef.isDefault) {
            setDialogData(e.colDef.question);
            setOpen(true);
          }
        }}
        sx={{
          backgroundColor: palette.neutral[100],
          height: "100%",
        }}
      />
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Stack direction="column" spacing={2}>
            <Typography
              variant="c18px600"
              sx={{
                color: palette.neutral[900],
              }}
            >
              Q{dialogData.uid}. {dialogData.title}
            </Typography>
            {dialogData.options?.map((option, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                sx={{ paddingTop: "16px" }}
              >
                <Typography
                  variant="c16px500"
                  sx={{ color: palette.neutral[500] }}
                >
                  Option{index + 1}:
                </Typography>
                <Typography>{option.title}</Typography>
              </Stack>
            ))}
          </Stack>
        </BootstrapDialogTitle>
      </BootstrapDialog>
    </Box>
  );
}
