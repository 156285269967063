import { useState } from "react";

import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils/utilsSlice";

import { Button, Dialog, FormControl, Stack } from "@mui/material";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/CustomDialog";
import {
  CustomInputLabel,
  CustomTextField,
} from "../../../components/CustomTextField";
import { getAuthorization } from "../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import NoData from "../../../components/Blockers/NoData";
import AddIcon from "@mui/icons-material/Add";

// import chart from "../../../assets/Chart.svg";

export default function AddTrial(props) {
  const [addTrialDialog, setAddTrialDialog] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // redux
  const dispatch = useDispatch();

  const handleCreateTrial = async (e) => {
    // handle form submit

    e.preventDefault();
    // remove errors from state, if any
    setError(null);
    // set loading to true
    setLoading(true);

    try {
      // make API request
      await axios({
        method: "POST",
        url: `${DOMAIN}/trials/`,
        data: {
          name,
          description,
        },
        headers: {
          Authorization: getAuthorization(),
        },
      });

      // notify
      dispatch(
        setNotify({
          open: true,
          action: "Successfully created trial",
          severity: "success",
          autoHideDuration: 5000,
          vertical: "bottom",
          horizontal: "right",
        })
      );

      // reload window if suceeded
      window.location.reload();
    } catch (err) {
      // set loading to false
      setLoading(false);
      try {
        // fetch error
        let error_object = JSON.parse(err.request.response);
        console.log(error_object.message);
        // set error
        setError(error_object.message);
      } catch {
        // default error message
        setError("Error while creating trial");
      }
    }
  };

  return (
    <>
      {props.isNew ? (
        <NoData
          message="Looks like you haven't started any trials yet"
          actionText="Start a Trial"
          action={() => setAddTrialDialog(true)}
        />
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => setAddTrialDialog(true)}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            startIcon={<AddIcon />}
          >
            Start New Trial
          </Button>
        </Stack>
      )}

      <Dialog
        open={addTrialDialog}
        setOpen={setAddTrialDialog}
        fullWidth
        onClose={() => setAddTrialDialog(false)}
      >
        <CustomDialogTitle onClose={() => setAddTrialDialog(false)}>
          Create New Trial
        </CustomDialogTitle>
        <CustomDialogContent>
          <FormControl fullWidth>
            <CustomInputLabel label="Enter Trial Name" />
            <CustomTextField
              fullWidth
              error={error ? true : false}
              helperText={error}
              variant="filled"
              placeholder="Trial Name"
              type="text"
              InputProps={{ disableUnderline: true }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <CustomInputLabel label="Enter Trial Description" />
            <CustomTextField
              multiline
              rows={4}
              fullWidth
              error={error ? true : false}
              helperText={error}
              variant="filled"
              placeholder="Trial Description"
              type="text"
              InputProps={{ disableUnderline: true }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </FormControl>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button
            disabled={loading}
            type="submit"
            disableElevation
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateTrial}
          >
            {loading ? "Creating Trial" : "Create Trial"}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
}
